import React from "react"

import { useFirebase } from "../../hooks/useFirebase"
import { useUserProfile } from "../../hooks/useUserProfile"

import Button from "../Button"

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
} from "../List"

import styled from "styled-components"

import { UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { User } from "firebase"
import { UserProfile } from "../../common/types"

const Root = styled.div<{visible: boolean}>`
  opacity: 0.0;
  visibility: hidden;

  ${ p => p.visible && `
    opacity: 1.0;
    visibility: visible;
  `}

  transition: opacity 300ms linear, visibility 300s;

  position: absolute;
  top: calc(100% + 1px); right: 0;

  padding: 1.5rem;

  z-index: 10;

  background-color: var(--theme-dark-2);
`

const Main = styled(List)`
  border-bottom: 1px solid var(--theme-border-color);
`
const Header = styled(ListItem)`
  margin-bottom: 2rem;
`
const HeaderItemIcon = styled(ListItemIcon)`
  width: 48px;
  height: 48px;
`
const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`
const HeaderPrimaryText = styled(ListItemPrimaryText)`
  font-size: 16px;
  font-weight: bold;
`
const HeaderSecondaryText = styled(ListItemSecondaryText)`
  font-size: 12px;
`

const SignOut = styled(Button)`
  margin: 0;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
`
SignOut.defaultProps = { compact: true, textOnly: true }

const HeaderItem: React.FC<{ user: User | null, profile: UserProfile | null}> =
  ({ user, profile }) => (
    <Header>
      <HeaderItemIcon>
        <ProfileImage src={ user?.photoURL || profile?.imageUrl } />
      </HeaderItemIcon>

      <ListItemText>
        <HeaderPrimaryText>{ profile?.firstName } { profile?.lastName }</HeaderPrimaryText>
        <HeaderSecondaryText>{ profile?.handle }</HeaderSecondaryText>
      </ListItemText>
    </Header>
  )

const ProfileItem: React.FC =
  () => (
    <Link to="/profile">
      <ListItem>
        <ListItemIcon>
          <UserOutlined style={{ color: "white", fontSize: "18px" }} />
        </ListItemIcon>

        <ListItemText>
          <ListItemPrimaryText style={{ fontSize: "16px" }}>Profile</ListItemPrimaryText>
        </ListItemText>
      </ListItem>
    </Link>
  )

interface ProfileOverlayPropsT {
  visible: boolean
  onVisibilityChange (v: boolean): void
}
const ProfileOverlay: React.FC<ProfileOverlayPropsT> =
  ({ visible, onVisibilityChange }) => {
    const { user, signOut } = useFirebase()
    const profile = useUserProfile()

    return (
      <Root visible={visible && !profile.isLoading} onClick={() => onVisibilityChange(!visible)}>
        <Main>
          <HeaderItem user={user} profile={profile} />
          <ProfileItem />

          { !profile.isTourGuide &&
            <ListItem onClick={() => window.open("https://teletour.com/become-a-tour-guide/", "_blank")}>
              {/* <ListItemIcon>
                <UserOutlined style={{ color: "white", fontSize: "18px" }} />
              </ListItemIcon> */}

              <ListItemText>
                <ListItemPrimaryText style={{ fontSize: "16px" }}>Become a Tour Guide</ListItemPrimaryText>
              </ListItemText>
            </ListItem>
          }
        </Main>

        <SignOut onClick={signOut}>Sign Out</SignOut>
      </Root>
    )
  }

export default ProfileOverlay