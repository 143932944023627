import React from "react"

import { Tour } from "../../common/types"

import TourListItem from "./TourListItem"
import TourListItemCompact from "./TourListItemCompact"
import TourListItemExpanded from "./TourListItemExpanded"

import styled from "styled-components"
import { useLayout } from "../Layout"

const Root = styled.div<{noscroll: boolean}>`
  position: relative;

  ${ p => !p.noscroll && `
    flex-grow: 1;
  `}

  display: flex;
  flex-direction: column;
`
const Header = styled.h4<{ compact?: boolean }>`
  padding: 1rem 0;

  color: white;
  z-index: 10;

  ${ p => p.compact ? `
    font-size: 12px;
    font-weight: 500;
    color: var(--theme-light-blue-grey);
  ` : `
    font-size: 20px;
    font-weight: 400;
  `}
`
const ScrollingContainer = styled.div<{ hasTitle: boolean, noscroll: boolean }>`
  ${ p => !p.noscroll && `
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;

    padding-right: 0.5rem;

    ${ p.hasTitle && `
      margin-top: 4rem;
    `}

    overflow-x: hidden;
    overflow-y: scroll;
  `}

  ::-webkit-scrollbar { width: 5px; }
  ::-webkit-scrollbar-track { }
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-dark-6);

    :hover {
      background-color: var(--theme-dusk);
    }
  }
`
const List = styled.ul<{ isPortrait: boolean, expanded: boolean }>`
  display: flex;
  flex-direction: column;
  list-style: none;

  ${p => p.expanded && !p.isPortrait && `
    flex-direction: row;
    flex-wrap: wrap;
  `};

  ${ p => !p.expanded && `
    > * {
      :first-child {
        margin-top: 0;
      }
    }
  `}
`

interface TourListPropsT {
  title?: string
  items: Tour[]
  itemFlags?: string[]
  compact?: boolean
  expanded?: boolean
  noscroll?: boolean
  onItemClick? (item: Tour): void
  owned?: boolean
}
const TourList: React.FC<TourListPropsT> =
  ({ title, items, owned, compact, expanded, noscroll, itemFlags, onItemClick }) => {
    const { isPortrait } = useLayout()

    let ListItemComponent = TourListItem

    if (compact) {
      ListItemComponent = TourListItemCompact
    } else if (expanded) {
      ListItemComponent = TourListItemExpanded
    }

    return (
      <Root noscroll={!!noscroll}>
        { title && <Header compact={compact}>{ title }</Header> }

        <ScrollingContainer noscroll={!!noscroll} hasTitle={!!title}>
          <List expanded={!!expanded} isPortrait={isPortrait}>
            {
              items.map(
                it =>
                  <ListItemComponent
                      owned={owned}
                      key={it.id}
                      item={it}
                      onClick={() => onItemClick ? onItemClick(it) : undefined } />
              )
            }
          </List>
        </ScrollingContainer>
      </Root>
    )
  }

export default TourList