import React, { createContext, useContext, useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

import { Tour } from "../../common/types"

import { useFirebase } from "../../hooks/useFirebase"
import { useUserProfile } from "../../hooks/useUserProfile"
import { useTours } from "../../hooks/useTours"

import { useMediaQuery } from "react-responsive"

import Button from "../Button"

import HomeMenu from "./HomeMenu"
import SearchInput from "./SearchInput"
import SearchOverlay from "./SearchOverlay"
import ProfileOverlay from "./ProfileOverlay"
import SignInDialog from "./SignInDialog"

import styled from "styled-components"

import { Drawer as AntDrawer, Tooltip } from "antd"

import LogoImage from "../../images/logo.svg"
import MenuIcon from "../../images/icons/menu.svg"
import AddVideoIcon from "../../images/icons/add-video.svg"

interface LayoutContextType {
  isPortrait: boolean
  isSignInVisible: boolean
  setMenuDocked (v: boolean): void
  setMenuExpanded (v: boolean): void
}

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType)
export const useLayout = () => useContext(LayoutContext)

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-dark-2);
`
const Header = styled.header`
  position: relative;
  padding: 1.5rem 2rem 1.5rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--theme-border-color);
`
const MenuButton = styled.img`
  margin: -4px 1rem 0 0;
  image-rendering: pixelated;
  cursor: pointer;
`
const CreateVideoButton = styled.img`
  margin-left: 32px;
  width: 27px;
`
CreateVideoButton.defaultProps = { src: AddVideoIcon }
MenuButton.defaultProps = { src: MenuIcon }
const AppLogo = styled.img`
  width: 8rem;
  cursor: pointer;
`
AppLogo.defaultProps = { src: LogoImage, alt: "Teletour Logo" }

const ProfileImage = styled.img`
  margin-left: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 1rem;
  cursor: pointer;
`
const Main = styled.main<{ isPortrait: boolean, overlayPresent: boolean }>`
  position: relative;

  flex-grow: 1;

  display: flex;
  flex-direction: row;

  background-color: var(--theme-dark-2);

  ${ p => p.isPortrait && `
    flex-direction: column;
  `}

  ${ p => p.overlayPresent && `
    opacity: 0.4;
    filter: blur(2px) grayscale(1);
    cursor: pointer;
  `}

  transition: all 200ms ease-in;
`
const Drawer = styled(AntDrawer)`
  position: absolute;
  width: 320px;

  .ant-drawer-content-wrapper {
    width: 320px !important;
  }

  .ant-drawer-content {
    overflow: hidden;
    background-color: var(--theme-dark-2);
  }

  .ant-drawer-body {
    padding: 0;
  }
`
Drawer.defaultProps = {
  closable: false,
  placement: "left",
}

const Layout: React.FC =
  ({ children }) => {
    const history = useHistory()
    const location = useLocation()
    const { isLoading, user } = useFirebase()
    const profile = useUserProfile()
    const { tours } = useTours()
    const isPortrait = useMediaQuery({ query: "(max-width: 920px)" })

    const notMenuDocked = useMediaQuery({ query: "(max-width: 1280px)" })

    const [ isHeaderVisible, setHeaderVisible ] = useState<boolean>(true)
    const [ isMenuDocked, setMenuDocked ] = useState<boolean>(false)
    const [ isMenuExpanded, setMenuExpanded ] = useState<boolean>(false)

    const [ showSearchOverlay, setSearchOverlay ] = useState<boolean>(false)
    const [ showProfileOverlay, setProfileOverlay ] = useState<boolean>(false)

    const [ isSignInVisible, setSignInVisible ] = useState<boolean>(false)

    const [ searchResults, setSearchResults ] = useState<Tour[]>([])

    useEffect(() => {
      setHeaderVisible(location.pathname === "/tours/upload" || location.pathname.slice(0,7) === "/update" ? false : true)
    }, [ location ])

    useEffect(() => {
      if (!user) {
        setSignInVisible(true)
        return
      }

      setSignInVisible(false)
    }, [user])

    useEffect(() => {
      setMenuDocked(!notMenuDocked)
      setMenuExpanded(!notMenuDocked)
    }, [ notMenuDocked ])

    if (isLoading) return <></>

    const showSignInDialog = () => setSignInVisible(true)
    const toggleProfileOverlay = () => setProfileOverlay(p => !p)

    const onSearchChange = (v: string) => {
      const q = v.trim().toLowerCase()

      if (q.length === 0) {
        setSearchOverlay(false)
        setSearchResults([])
        return
      }

      const results = tours.filter(it => JSON.stringify(it).toLowerCase().includes(q))
      setSearchOverlay(true)
      setSearchResults(results)
    }

    const onSearchItemClick = (it: Tour) => {
      history.push(`/tours/${it.id}`)
      setSearchOverlay(false)
    }

    return (
      <LayoutContext.Provider value={{ isPortrait, isSignInVisible, setMenuDocked, setMenuExpanded }}>
        <Root>
          { isHeaderVisible &&
            <Header>
              { !isSignInVisible && user &&
                <MenuButton onClick={() => setMenuExpanded(p => !p)}/>
              }

              <AppLogo onClick={() => history.push("/")} />

              <div style={{flexGrow: 1}} />

              { !isPortrait && !isSignInVisible &&
                <SearchInput onChange={ onSearchChange } />
              }

              { !isSignInVisible && user?.isAnonymous &&
                <Button onClick={() => showSignInDialog()}>Login</Button>
              }
              { user && !user?.isAnonymous &&
                <>
                  { profile.isTourGuide && profile.isVODEnabled &&
                    <Tooltip placement="bottom" title="Create Tour">
                      <Link to="/tours/upload">
                        <CreateVideoButton />
                      </Link>
                    </Tooltip>
                  }
                  <ProfileImage src={profile.imageUrl || user?.photoURL || ""} onClick={toggleProfileOverlay} />
                </>
              }

              <SearchOverlay visible={showSearchOverlay} results={searchResults} onItemClick={onSearchItemClick}/>
              <ProfileOverlay visible={showProfileOverlay} onVisibilityChange={setProfileOverlay} />
            </Header>
          }

          { isSignInVisible && <SignInDialog onClose={() => setSignInVisible(false)} /> }

          <Main isPortrait={isPortrait} overlayPresent={showSearchOverlay || showProfileOverlay} onClick={ ev => {
                if (showSearchOverlay || showProfileOverlay) {
                  ev.stopPropagation()
                  setSearchOverlay(false)
                  setProfileOverlay(false)
                }
              }}>
            { isHeaderVisible && !isMenuDocked && isMenuExpanded &&
              <Drawer visible={!isMenuDocked && isMenuExpanded} getContainer={false} onClose={() => setMenuExpanded(false)}>
                <HomeMenu isPortrait={true} onClick={() => setMenuExpanded(false)} />
              </Drawer>
            }

            { isHeaderVisible && !isSignInVisible && isMenuDocked && isMenuExpanded &&
              <HomeMenu isPortrait={false} onClick={() => setMenuExpanded(false)} />
            }

            { children }
          </Main>
        </Root>
      </LayoutContext.Provider>
    )
  }

export default Layout