import React from "react"
import { NavLink, useHistory } from "react-router-dom"

import { useUserProfile } from "../../hooks/useUserProfile"
import { useTours } from "../../hooks/useTours"

import TourList from "../TourList"
import FollowingList from "../GuideList"

import styled from "styled-components"

import HomeIcon from "../../images/icons/home.svg"
import SearchInput from "./SearchInput"

const Root = styled.div`
  margin-top: -1px;
  min-width: 320px;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-dark-2);
  border-right: 1px solid var(--theme-border-color);;
`
const HomeItem = styled(NavLink)`
  padding: 1rem 2rem 1rem 4.5rem;
  font-size: 16px;
  font-weight: bold;
  color: white;

  background-color: var(--theme-dark-2);
  background-image: url(${HomeIcon});
  background-repeat: no-repeat;
  background-position: 2rem center;

  &.is-active {
    background-color: var(--theme-dark-6);
  }

  :hover {
    color: white;
    background-color: var(--theme-dusk);
  }

  transition: 0.750s background-color;
`
HomeItem.defaultProps = { exact: true, activeClassName: "is-active" }

const SearchItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--theme-border-color);
`
const Main = styled.div`
  padding: 0 0 0 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
`

interface HomeMenuPropsT {
  isPortrait: boolean
  onClick (): void
}
const HomeMenu: React.FC<HomeMenuPropsT> =
  ({ isPortrait, onClick }) => {
    const history = useHistory()
    const profile = useUserProfile()

    const { live, upcoming } = useTours()

    return (
      <Root onClick={onClick}>
        { isPortrait &&
          <SearchItem>
            <SearchInput />
          </SearchItem>
        }

        <HomeItem to="/" onClick={onClick}>Home</HomeItem>

        <Main>
          { live.length > 0 &&
            <TourList compact noscroll items={live.slice(0, 2)}
                title="Live Tours"
                onItemClick={it => history.push(`/tours/${it.id}`)} />
          }

          { upcoming.length > 0 &&
            <TourList compact noscroll items={upcoming.slice(0, 2)}
                title="Upcoming Tours"
                onItemClick={it => history.push(`/tours/${it.id}`)} />
          }

          { profile.following.length > 0 &&
            <FollowingList items={profile.following}
                title="Following"
                onItemClick={it => history.push(`/guides/${it.id}`)} />
          }
        </Main>
      </Root>
    )
  }

export default HomeMenu