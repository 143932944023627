import React from "react"
import { Switch, Route, useHistory, useLocation } from "react-router-dom"

import { useFirebase } from "./hooks/useFirebase"

import HomePage from "./pages"
import GuideViewPage from "./pages/guides/[guideId]"
import TourViewPage from "./pages/tours/[tourId]"
import { UploadPage } from "./pages/tours/upload"
import { EditPage } from "./pages/tours/edit"

import ProfileEditPage from "./pages/profile/edit"
import ProfileViewPage from "./pages/profile"

const Routes: React.FC =
  () => {
    const { user } = useFirebase()
    const history = useHistory()
    const location = useLocation()

    if (user === null && location.pathname !== "/") {
      history.replace("/")
      return <></>
    }

    return (
      <Switch>
        <Route exact path="/" component={ HomePage } />
        <Route exact path="/update/:tourId" component={ EditPage } />
        <Route exact path="/guides/:guideId" component={ GuideViewPage } />

        <Route exact path="/tours/upload" component={ UploadPage } />
        <Route exact path="/tours/:tourId" component={ TourViewPage } />

        <Route exact path="/profile/edit" component={ ProfileEditPage } />
        <Route exact path="/profile" component={ ProfileViewPage } />
      </Switch>
    )
  }

export default Routes