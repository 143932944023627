import React from "react"
import { Link } from "react-router-dom"

import { IMapMarker } from "./IMapProps"

import styled from "styled-components"

import MarkerImage from "../../images/icons/marker.png"

const Marker = styled.img`
  width: 27px;
  position: relative;
  left: -13.5px;
  top: -27px;
`
Marker.defaultProps = {
  alt: "Marker",
  src: MarkerImage,
}

export const MapMarker = ({ tour }: IMapMarker) => (
  tour ?
    <Link to={`/tours/${tour.id}`}>
      <Marker />
    </Link>
    :
    <Marker />
)

export default MapMarker