import React from "react"
import { useHistory } from "react-router-dom"

import { Tour } from "../common/types"

import { useTours } from "../hooks/useTours"
import { useLayout } from "../components/Layout"

import TourList from "../components/TourList"
import MapComponent from "../components/Map/MapComponent"

import styled from "styled-components"
import { sortByProperty } from "../common/utils"

const Root = styled.div<{isPortrait: boolean}>`
  display: flex;
  flex-grow: 1;

  ${ p => !p.isPortrait && `
    flex-direction: row;
  `}

  ${ p => p.isPortrait && `
    margin: 0 1rem;
    flex-direction: column-reverse;
    overflow: hidden;
  `}
`
const TourListContainer = styled.div<{isPortrait: boolean}>`
  min-width: 360px;
  display: flex;
  flex-direction: column;

  ${ p => !p.isPortrait && `
    padding: 0 0.5rem 0 2rem;
  `}

  ${ p => p.isPortrait && `
    flex-grow: 1;
  `}
`
const Main = styled.div<{ isPortrait: boolean, isSignInVisible: boolean }>`
  ${ p => (!p.isPortrait || p.isSignInVisible) && `
    flex-grow: 1;
  `}
`

const HomePage: React.FC =
  () => {
    const history = useHistory();
    const { isPortrait, isSignInVisible } = useLayout();

    const { tours } = useTours();

    const sortedTours = tours.sort(sortByProperty("date")).reverse();
    const filteredTours: Tour[] = sortedTours;

    return (
      <Root isPortrait={isPortrait}>
        { !isSignInVisible &&
          <TourListContainer isPortrait={isPortrait}>
            <TourList title="Tours"
                items={filteredTours}
                onItemClick={it => history.push(`/tours/${it.id}`)} />
          </TourListContainer>
        }

        <Main isPortrait={isPortrait} isSignInVisible={isSignInVisible}>
          <MapComponent fullHeight={!isPortrait || isSignInVisible} />
        </Main>
      </Root>
    )
  }

export default HomePage