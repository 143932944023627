import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { FirebaseProvider } from "./hooks/useFirebase"
import { UserProfileProvider } from "./hooks/useUserProfile"

import { TourGuidesProvider } from "./hooks/useTourGuides"
import { ToursProvider } from "./hooks/useTours"

import Routes from "./routes"
import Layout from "./components/Layout"

const App: React.FC =
  () => {
    return (
      <FirebaseProvider>
        <UserProfileProvider>
          <TourGuidesProvider>
            <ToursProvider>
              <Router>
                <Layout>
                  <Routes />
                </Layout>
              </Router>
            </ToursProvider>
          </TourGuidesProvider>
        </UserProfileProvider>
      </FirebaseProvider>
    )
  }

export default App