interface Map {
  [key: string]: string;
};

export const CountryToContinent: Map = {
  "AD": "europe",
  "AE": "asia",
  "AF": "asia",
  "AG": "northAmerica",
  "AI": "northAmerica",
  "AL": "europe",
  "AM": "asia",
  "AN": "northAmerica",
  "AO": "africa",
  "AQ": "antarctica",
  "AR": "southAmerica",
  "AS": "australia",
  "AT": "europe",
  "AU": "australia",
  "AW": "northAmerica",
  "AZ": "asia",
  "BA": "europe",
  "BB": "northAmerica",
  "BD": "asia",
  "BE": "europe",
  "BF": "africa",
  "BG": "europe",
  "BH": "asia",
  "BI": "africa",
  "BJ": "africa",
  "BM": "northAmerica",
  "BN": "asia",
  "BO": "southAmerica",
  "BR": "southAmerica",
  "BS": "northAmerica",
  "BT": "asia",
  "BW": "africa",
  "BY": "europe",
  "BZ": "northAmerica",
  "CA": "northAmerica",
  "CC": "asia",
  "CD": "africa",
  "CF": "africa",
  "CG": "africa",
  "CH": "europe",
  "CI": "africa",
  "CK": "australia",
  "CL": "southAmerica",
  "CM": "africa",
  "CN": "asia",
  "CO": "southAmerica",
  "CR": "northAmerica",
  "CU": "northAmerica",
  "CV": "africa",
  "CX": "asia",
  "CY": "asia",
  "CZ": "europe",
  "DE": "europe",
  "DJ": "africa",
  "DK": "europe",
  "DM": "northAmerica",
  "DO": "northAmerica",
  "DZ": "africa",
  "EC": "southAmerica",
  "EE": "europe",
  "EG": "africa",
  "EH": "africa",
  "ER": "africa",
  "ES": "europe",
  "ET": "africa",
  "FI": "europe",
  "FJ": "australia",
  "FK": "southAmerica",
  "FM": "australia",
  "FO": "europe",
  "FR": "europe",
  "GA": "africa",
  "GB": "europe",
  "GD": "northAmerica",
  "GE": "asia",
  "GF": "southAmerica",
  "GG": "europe",
  "GH": "africa",
  "GI": "europe",
  "GL": "northAmerica",
  "GM": "africa",
  "GN": "africa",
  "GP": "northAmerica",
  "GQ": "africa",
  "GR": "europe",
  "GS": "antarctica",
  "GT": "northAmerica",
  "GU": "australia",
  "GW": "africa",
  "GY": "southAmerica",
  "HK": "asia",
  "HN": "northAmerica",
  "HR": "europe",
  "HT": "northAmerica",
  "HU": "europe",
  "ID": "asia",
  "IE": "europe",
  "IL": "asia",
  "IM": "europe",
  "IN": "asia",
  "IO": "asia",
  "IQ": "asia",
  "IR": "asia",
  "IS": "europe",
  "IT": "europe",
  "JE": "europe",
  "JM": "northAmerica",
  "JO": "asia",
  "JP": "asia",
  "KE": "africa",
  "KG": "asia",
  "KH": "asia",
  "KI": "australia",
  "KM": "africa",
  "KN": "northAmerica",
  "KP": "asia",
  "KR": "asia",
  "KW": "asia",
  "KY": "northAmerica",
  "KZ": "asia",
  "LA": "asia",
  "LB": "asia",
  "LC": "northAmerica",
  "LI": "europe",
  "LK": "asia",
  "LR": "africa",
  "LS": "africa",
  "LT": "europe",
  "LU": "europe",
  "LV": "europe",
  "LY": "africa",
  "MA": "africa",
  "MC": "europe",
  "MD": "europe",
  "ME": "europe",
  "MG": "africa",
  "MH": "australia",
  "MK": "europe",
  "ML": "africa",
  "MM": "asia",
  "MN": "asia",
  "MO": "asia",
  "MP": "australia",
  "MQ": "northAmerica",
  "MR": "africa",
  "MS": "northAmerica",
  "MT": "europe",
  "MU": "africa",
  "MV": "asia",
  "MW": "africa",
  "MX": "northAmerica",
  "MY": "asia",
  "MZ": "africa",
  "NA": "africa",
  "NC": "australia",
  "NE": "africa",
  "NF": "australia",
  "NG": "africa",
  "NI": "northAmerica",
  "NL": "europe",
  "NO": "europe",
  "NP": "asia",
  "NR": "australia",
  "NU": "australia",
  "NZ": "australia",
  "OM": "asia",
  "PA": "northAmerica",
  "PE": "southAmerica",
  "PF": "australia",
  "PG": "australia",
  "PH": "asia",
  "PK": "asia",
  "PL": "europe",
  "PM": "northAmerica",
  "PN": "australia",
  "PR": "northAmerica",
  "PS": "asia",
  "PT": "europe",
  "PW": "australia",
  "PY": "southAmerica",
  "QA": "asia",
  "RE": "africa",
  "RO": "europe",
  "RS": "europe",
  "RU": "europe",
  "RW": "africa",
  "SA": "asia",
  "SB": "australia",
  "SC": "africa",
  "SD": "africa",
  "SE": "europe",
  "SG": "asia",
  "SH": "africa",
  "SI": "europe",
  "SJ": "europe",
  "SK": "europe",
  "SL": "africa",
  "SM": "europe",
  "SN": "africa",
  "SO": "africa",
  "SR": "southAmerica",
  "ST": "africa",
  "SV": "northAmerica",
  "SY": "asia",
  "SZ": "africa",
  "TC": "northAmerica",
  "TD": "africa",
  "TF": "antarctica",
  "TG": "africa",
  "TH": "asia",
  "TJ": "asia",
  "TK": "australia",
  "TM": "asia",
  "TN": "africa",
  "TO": "australia",
  "TR": "asia",
  "TT": "northAmerica",
  "TV": "australia",
  "TW": "asia",
  "TZ": "africa",
  "UA": "europe",
  "UG": "africa",
  "US": "northAmerica",
  "UY": "southAmerica",
  "UZ": "asia",
  "VC": "northAmerica",
  "VE": "southAmerica",
  "VG": "northAmerica",
  "VI": "northAmerica",
  "VN": "asia",
  "VU": "australia",
  "WF": "australia",
  "WS": "australia",
  "YE": "asia",
  "YT": "africa",
  "ZA": "africa",
  "ZM": "africa",
  "ZW": "africa"
};