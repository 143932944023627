import React, { createContext, useContext, useEffect, useState } from "react"

import firebase from "firebase/app"
import "firebase/auth"

import config from "../config"

export interface IAuth {
  user: firebase.User | null
  isLoading: boolean

  signInWithApple (): Promise<void>
  signInWithGoogle (): Promise<void>
  signInAnonymously (): Promise<void>

  signOut (): Promise<void>
}

export const Context = createContext({ isLoading: true } as IAuth)
export const useFirebase = () => useContext(Context)

interface InitFirebaseOptsT {
  tenantId?: string
}
const initFirebaseApi = (options: InitFirebaseOptsT): any => {
  if (!firebase.apps.length) firebase.initializeApp(config.firebase)
  const auth = firebase.auth()
  auth.tenantId = options.tenantId || null

  return { auth }
}

interface FirebaseProviderPropsT {
  tenantId?: string
}
export const FirebaseProvider: React.FC<FirebaseProviderPropsT> =
  ({ tenantId, children }) => {
    const [ isLoading, setLoading ] = useState<boolean>(true)
    const [ user, setUser ] = useState<firebase.User | null>(null);

    useEffect(() => {
      initFirebaseApi({ tenantId })

      firebase.auth().onAuthStateChanged((user) => {
        setUser(user)
        setLoading(false)
      })
    }, [ tenantId ])

    const signInWithApple = async () => {
      const provider = new firebase.auth.OAuthProvider("apple.com")
      provider.addScope("email")
      provider.addScope("name")
      await firebase.auth().signInWithPopup(provider)
    }

    const signInWithGoogle = async () => {
      const provider = new firebase.auth.GoogleAuthProvider()
      await firebase.auth().signInWithPopup(provider)
    }

    const signInAnonymously = async () => {
      await firebase.auth().signInAnonymously()
    }

    const signOut = async () => {
      setLoading(true)
      await firebase.auth().signOut()
      setUser(null)
      setLoading(false)
    }

    return (
      <Context.Provider value={{
            user,
            isLoading,
            signInWithApple,
            signInWithGoogle,
            signInAnonymously,
            signOut,
          }}>
        { children }
      </Context.Provider>
    )
  }
