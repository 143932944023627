import React from "react"
import { Link } from "react-router-dom"

import styled from "styled-components"

import { fmtDuration, fmtTimeFromNow } from "../../common/utils"
import { TourListItemPropsT } from "./TourListItem"

import { Tooltip } from "antd"
import { useLayout } from "../Layout"

import TourPlaceholder from "../../images/icons/tour-placeholder.png"
import edit from '../../images/icons/edit.svg'

const Root = styled.li<{ isPortrait: boolean }>`
  margin: 1.5rem 1rem;

  display: flex;
  flex-direction: column;

  ${ p => !p.isPortrait && `
    width: 260px;
  `}
`
const Media = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 53.84%;
  width: 100%;
  cursor: pointer;
`
const MediaImage = styled.img`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
`
const MediaDuration = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;

  border-radius: 0.3rem 0 0.9rem 0;

  padding: 6px 8px 4px 8px;
  font-size: 10px;
  font-weight: bold;
  display: block;
  background-color: var(--theme-dark-1);
  color: white;
  opacity: 0.8;
`

const TourInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const TourTitle = styled.h5`
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`
const TourGuide = styled.h6`
  font-size: 12px;
  font-weight: normal;
  color: var(--theme-very-light-brown);
`
const TourSummary = styled.span`
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: normal;
  color: white; 
`
const TourTitleContainer = styled.div`
  display: flex;
  justif-content: space-between;
  align-items: center
`
const TourListItemExpanded: React.FC<TourListItemPropsT> =
  ({ item, owned, onClick }) => {
    const { isPortrait } = useLayout()

    return (
      <Root isPortrait={isPortrait}>
        <Media onClick={onClick}>
          <MediaImage src={item.imageUrl || TourPlaceholder} />
          {/* <MediaDuration>{ fmtDuration(item.tourDuration) }</MediaDuration> */}
        </Media>

        <TourInfo>
          <Tooltip title={ item.name }>
            <TourTitleContainer>          
            <TourTitle onClick={onClick}>{ item.name }</TourTitle>
            {!!owned && <Link to={`/update/${item.id}`}>
            <img src={edit} alt="SVG" height={30} width={30}></img>
           </Link>}
           </TourTitleContainer>
          </Tooltip>
          <Link to={`/guides/${item.userId}`}>
            <TourGuide>{ item.guideName }</TourGuide>
          </Link>
       
          <TourSummary>{/* "230 views |" */} { fmtTimeFromNow(item.date) }</TourSummary>
        </TourInfo>
      </Root>
    )
  }

export default TourListItemExpanded