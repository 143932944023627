import React from "react"

import { Tour } from "../../common/types"

import TourList from "../TourList"

import styled from "styled-components"

const Root = styled.div<{visible: boolean}>`
  opacity: 0.0;
  visibility: hidden;

  ${ p => p.visible && `
    opacity: 1.0;
    visibility: visible;
  `}

  transition: opacity 300ms linear, visibility 300s;

  position: absolute;
  top: calc(100% + 1px); right: 6rem;

  padding: 1.5rem;

  z-index: 10;

  background-color: var(--theme-dark-2);
`

interface SearchOverlayPropsT {
  visible: boolean
  results: Tour[]
  onItemClick (it: Tour): void
}

const SearchOverlay: React.FC<SearchOverlayPropsT> =
  ({ visible, results, onItemClick }) => (
    <Root visible={visible}>
      { results.length === 0 &&
        <p>No Results Found</p>
      }
      { results.length > 0 &&
        <TourList compact noscroll items={results} onItemClick={onItemClick} />
      }
    </Root>
  )

export default SearchOverlay