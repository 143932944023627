import React, { createContext, useContext, useEffect, useState } from "react"

import { useFirebase } from "./useFirebase"

import { TourGuide, UserProfile } from "../common/types"
import { getTourGuides, getFollowers as getUserFollowers } from "../common/api-client"

interface TourGuidesContextT {
  isLoading: boolean

  guides: TourGuide[]

  getFollowers (guideId: string): Promise<UserProfile[]>
}

const initialState = {
  isLoading: true,
  guides: [],

  getFollowers: (guideId: string) => Promise.resolve([])
}

const Context = createContext<TourGuidesContextT>(initialState as TourGuidesContextT)
export const useTourGuides = () => useContext(Context)

export const TourGuidesProvider: React.FC =
  ({ children }) => {
    const { user } = useFirebase()

    const [ isLoading, setLoading ] = useState<boolean>(true)
    const [ guides, setGuides ] = useState<TourGuide[]>([])

    useEffect(() => {
      if (!user) return

      getTourGuides()
        .then(r => {
          if (r.error) throw new Error(r.error)

          setGuides(r)
          setLoading(false)
        })
        .catch (e => {
          console.error("Failed to get guides:", e)
          setLoading(false)
        })
    }, [ user ])

    const getFollowers = async (guideId: string): Promise<UserProfile[]> => {
      const results = await getUserFollowers(guideId)
        .catch(e => {
          console.error("Failed to get guide followers:", e)
          setLoading(false)
        })
        setLoading(false)

        if (!results) return []
        if (results.error) throw new Error(results.error)

        return results
    }

    return (
      <Context.Provider value={{ isLoading, guides, getFollowers }}>
        { children }
      </Context.Provider>
    )
  }