import styled, { css } from "styled-components"

interface TextAreaPropsT {
  invalid?: boolean
}

export const TextArea = styled.textarea<TextAreaPropsT>`
  margin: 8px 0;
  padding: 12px 28px;
  font-size: 16px;
  min-height: 12rem;
  border: none;
  border-radius: 16px;
  background-color: var(--theme-dark-3);
  outline: none;
  resize: none;

  ::placeholder {
    color: var(--theme-blue-grey-2);
  }

  ${p => p.invalid && css`
    outline: 2px dashed white;
  `}
`