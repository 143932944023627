const dev = {
  firebase: {
    apiKey: 'AIzaSyDprxJZR5XYCwGfj6cGHziuM_lnsgfDZnM',
    authDomain: 'teletour-dev.firebaseapp.com',
    databaseURL: 'https://teletour-dev.firebaseio.com',
    projectId: 'teletour-dev',
    storageBucket: 'teletour-dev.appspot.com',
    messagingSenderId: '61665889546',
    name: 'Teletour Dev',
    api: 'https://api.dev.teletour.com',
  },
  mapsApi: 'AIzaSyDprxJZR5XYCwGfj6cGHziuM_lnsgfDZnM',
};

const prod = {
  firebase: {
    apiKey: 'AIzaSyCMy3oanm9jJ5vHE9XPin1Lv0DUh4mOHdY',
    authDomain: 'teletour-prod.firebaseapp.com',
    databaseURL: 'https://teletour-prod.firebaseio.com',
    projectId: 'teletour-prod',
    storageBucket: 'teletour-prod.appspot.com',
    messagingSenderId: '61665889546',
    name: 'Teletour Prod',
    api: 'https://api.prod.teletour.com',
  },
  mapsApi: 'AIzaSyCMy3oanm9jJ5vHE9XPin1Lv0DUh4mOHdY',
};

const config: any = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;