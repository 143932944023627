import styled from "styled-components"

import MapBackground from "../../images/map@2x.png"

export const Root = styled.div`
  position: relative;
  width: 100%; height: 100%;
  flex-grow: 1;
`

export const Container = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar { display: none; }
  ::-webkit-scrollbar-track { }
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-dark-6);

    :hover {
      background-color: var(--theme-dusk);
    }
  }
`

export const Header = styled.div`
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  background: rgb(29, 33, 55) url(${MapBackground}) no-repeat;
  background-size: 50vw;
  background-position: center 20%;
`

export const ProfileImage = styled.img`
  margin-right: 1.5rem;
  width: 88px;
  height: 88px;
  border-radius: 1rem;
`

export const ProfileInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > * {
    margin: 0.4rem 0;
    padding: 0;
    line-height: 1;
  }
`

export const ProfileName = styled.h2`
  font-size: 22px;
  font-weight: medium;
`

export const ProfileHandle = styled.span`
  font-size: 18px;
  font-weight: medium;
  color: var(--theme-very-light-brown);
`

export const ProfileHometown = styled.span`
  margin-right: 1rem;
  font-size: 14px;
  font-weight: normal;
`

export const ProfileFollowers = styled.span`
  font-size: 14px;
  font-weight: normal;
`

export const ProfileBio = styled.p`
  margin: 1rem 0 0;
  font-size: 18px;
  font-weight: normal;
  color: var(--theme-very-light-brown);
`;

export const Main = styled.div`
  flex-grow: 1;
`