import React, { useRef, useState } from "react"
import styled, { css } from "styled-components"

import Button from "../Button"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
  SectionDescription,
} from "../../components/FormCommon"

import { CheckOutlined } from "@ant-design/icons"

const MediaUploadInputRoot = styled.div<{isActive: boolean}>`
  position: relative;

  margin: 36px 0 0;
  padding: 36px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: var(--theme-dark-3);

  cursor: pointer;

  ${ p => p.isActive && css`
    background-color: var(--theme-dusk);
  `}
`
const MediaUploadInputTitle = styled.h4`
  padding: 0 0 12px;
  font-size: 16px;
  font-weight: bold;
`
const MediaUploadInputDescription = styled.p`
  font-size: 16px;
`
const MediaUploadInputComment = styled.p`
  font-size: 14px;
`
const MediaUploadButton = styled(Button)`
  margin: 24px 0 0 0;
`
const MediaUploadDropZone = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
`
const RequirementList = styled.ul`
  display: inline-block;
  margin: 2rem 0 0 1rem;
  list-style: none;
`
const RequirementListItem = styled.li`
  margin: 1rem 0;
  display: flex;
  align-items: center;

  > span:first-of-type {
    margin-right: 2rem;
    flex-grow: 1;
  }

  > span[role=img] {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    font-size: 14px;
    color: var(--theme-light-aquamarine);
  }
`

interface MediaUploadInputPropsT {
  onFileSelected (target: File): void
}

export const MediaUploadInput: React.FC<MediaUploadInputPropsT> =
  ({ onFileSelected }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [ isActive, setActive ] = useState<boolean>(false)

    const onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault()
    }

    const onDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault()
      setActive(true)
    }

    const onDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault()
      setActive(false)
    }

    const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault()
      setActive(false)
      onFileSelected(ev.dataTransfer.files[0] || null)
    }

    const onFileInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.preventDefault()
      if (ev.target.files && ev.target.files.length > 0) {
        onFileSelected(ev.target.files[0])
      }
    }

    return (
      <MediaUploadInputRoot isActive={isActive} onClick={() => inputRef.current?.click()}>
        <input type="file" style={{display: "none"}} ref={inputRef} onChange={onFileInputChange} />

        <MediaUploadInputTitle>Add Tour Video</MediaUploadInputTitle>
        <MediaUploadInputDescription>Drag and drop video and pictures to upload</MediaUploadInputDescription>
        <MediaUploadInputComment>Video files must be converted to MP4 format.</MediaUploadInputComment>
        <MediaUploadButton type="button" secondary>+ Select Files</MediaUploadButton>

        <MediaUploadDropZone
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop} />
      </MediaUploadInputRoot>
    )
  }

interface MediaUploadPropsT {
  imageFile: File | null
  videoFile: File | null

  onFileSelected (upload: File): void
}
export const MediaUploadSection = React.forwardRef<HTMLDivElement, MediaUploadPropsT>(
  ({ imageFile, videoFile, onFileSelected }, ref) => {
    return (
      <Section ref={ref}>
        <SectionTitle>Tour Upload Page</SectionTitle>
        <SectionSubtitle>Add video and cover picture</SectionSubtitle>

        <SectionDescription>
          This helps to sell your tour and gives people a taste of what they will see.
        </SectionDescription>

        <RequirementList>
          <RequirementListItem>
            <span>Tour Video</span>
            { videoFile && <CheckOutlined /> }
          </RequirementListItem>
          <RequirementListItem>
            <span>Tour Cover Image</span>
            { imageFile && <CheckOutlined /> }
          </RequirementListItem>
        </RequirementList>

        { (!imageFile || !videoFile) &&
          <MediaUploadInput onFileSelected={onFileSelected} />
        }
      </Section>
    )
  }
)