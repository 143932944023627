import React, { useState, useEffect } from "react"

export interface FileInfoT {
  name: string;
  type: string;
  size: number;
};

interface FileUploadPropsT {
  asArrayBuffer?: boolean;
  asDataUrl?: boolean;
  asObjectUrl?: boolean;
  asStream?: boolean;
};

const useFileUpload = (props: FileUploadPropsT) => {
  const [ fileInfo, setFileInfo ] = useState<FileInfoT | null>(null);
  const [ fileContent, setFileContent ] = useState<any>(null);

  const onFileInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const input = ev.target;

    setFileInfo(null);
    setFileContent(null);

    if (!input || !input.files) return;

    if (input.files.length === 0) {
      console.warn("No file/s selected.");
      return;
    }

    const [ file ]: any[] = Array.from(input.files);
    const { name, size, type } = file;

    setFileInfo({ name, type, size })

    const consume = () => {
      if (props.asArrayBuffer) {
        return file.arrayBuffer();
      } else if (props.asDataUrl) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      } else if (props.asObjectUrl) {
        return Promise.resolve(URL.createObjectURL(file));
      } else if (props.asStream) {
        return Promise.resolve(file.stream());
      } else {
        return file.text();
      }
    }

    consume().then((t: any) => setFileContent(t));  }

  return {
    fileInfo,
    fileContent,
    onFileInputChange,
  }
};

interface CustomFileUploadPropsT {
  result: string;
  useEffect (info: FileInfoT, content: any, setResult: React.Dispatch<any>): void
};

export const useCustomFileUpload = <T, >(props: FileUploadPropsT & CustomFileUploadPropsT) => {
    const {
      fileInfo,
      fileContent,
      onFileInputChange
    } = useFileUpload(props);

    const [ result, setResult ] = useState<any>(null);

    useEffect(() => {
      if (fileInfo === null || fileContent === null) return;

      props.useEffect(fileInfo, fileContent, setResult);
    }, [ props, fileInfo, fileContent ]);

    return {
      [ props.result ]: result as T,
      fileInfo,
      onFileInputChange,
    };
  };

export const useJsonUpload = <T, >() => useCustomFileUpload<T>({
  result: "data",
  useEffect: (info, content, setResult) => {
    if (info.type !== "application/json") {
      console.warn("Upload is not application/json type.");
      return;
    }

    setResult(JSON.parse(content));
  }
});

export const useImageUpload = () => useCustomFileUpload<string>({
  result: "image",
  asDataUrl: true,
  useEffect: (info, content, setResult) => {
    if (! /^image\//.test(info.type)) {
      console.warn("Upload is not an image/* type");
      return;
    }

    setResult(content);
  }
});

export default useFileUpload;
