import styled from "styled-components"

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`
export const ListItem = styled.li`
  margin: 0 0 0.8rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
export const ListItemIcon = styled.div`
  margin: 0 12px 0 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ListItemText = styled.div`
  display: flex;
  flex-direction: column;
`
export const ListItemPrimaryText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: white;
`
export const ListItemSecondaryText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: var(--theme-very-light-brown);
`