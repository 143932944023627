import * as firebase from 'firebase/app';
import config from '../config';

import { MediaUploadResponse, Tour, TourGuide, UserProfile } from './types';
// eslint-disable-next-line no-unused-vars

const API = config.firebase.api;

interface ErrorResponse {
  error: string
}

interface Headers {
  [index: string]: string;
  Authorization: string;
  "x-api-key": string;
}

export async function getHeaders(): Promise<Headers> {
  const headers: Headers = {
    'x-api-key': config.firebase.apiKey,
    Authorization: ''
  }
  let token;
  const { currentUser } = firebase.auth();
  if (currentUser) {
    token = await currentUser.getIdToken();
  }
  if (token) {
    headers.Authorization = token;
  }
  return headers;
}

async function GET<T> (path: string): Promise<T & ErrorResponse> {
  const req: RequestInit = {
    method: "GET",
    headers: await getHeaders()
  }

  return await (await fetch(`${API}${path}`, req)).json()
}

async function POST<T> (path: string, data: any = {}): Promise<T & ErrorResponse> {
  const req: RequestInit = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(data),
  }
  const res = await fetch(`${API}${path}`, req)

  if(res.headers.has("content-length") && parseInt(res.headers.get("content-length") || "0") > 0) {
    return await res.json()
  }

  return {} as any
}

async function PUT<T> (path: string, data: any): Promise<Response> {
  console.info(data)
  const req: RequestInit = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify(data),
  }
  return await fetch(`${API}${path}`, req)
}

export async function createMe (profile: any): Promise<UserProfile & ErrorResponse> {
  return await POST<UserProfile>("/me", profile)
}

export async function getMe(): Promise<UserProfile & ErrorResponse> {
  return await GET<UserProfile>("/me")
}

export async function getFollowing(userId?: string): Promise<TourGuide[] & ErrorResponse> {
  userId = userId || firebase.auth().currentUser?.uid
  return await GET<TourGuide[]>(`/users/${userId}/following`)
}

export async function getFollowers(userId?: string): Promise<UserProfile[] & ErrorResponse> {
  userId = userId || firebase.auth().currentUser?.uid
  return await GET<UserProfile[]>(`/users/${userId}/followers`)
}

export async function followGuide(guideId: string): Promise<any & ErrorResponse> {
  return await POST<any>(`/users/${guideId}/follow`)
}

export async function updateMe (updates: any): Promise<any & ErrorResponse> {
  return await PUT<UserProfile>("/me", updates)
}

export async function updateProfileImage (contentType: string): Promise<MediaUploadResponse & ErrorResponse> {
  return await POST<MediaUploadResponse>("/me/media", { title: "Profile", description: "Profile", contentType })
}

export async function getTour(id: string): Promise<Tour & ErrorResponse> {
  return await GET<Tour>(`/tours/${id}`)
}

// getHeaders();
// {{domain}}/tours?continent=northAmerica
export async function getTours(): Promise<Tour[] & ErrorResponse> {
  return await GET<Tour[]>("/tours")
}

export async function createTour(data: any): Promise<Tour & ErrorResponse> {
  return await POST<Tour>("/tours", data)
}

export async function updateTour(id: string, data: any): Promise<void> {
  await PUT<Tour>(`/tours/${id}`, data)
}

export async function createSignUrl(id: string, data: any): Promise<any> {
  return await POST<any>(`/tours/${id}`, data)
}

export async function getTourGuides(): Promise<TourGuide[] & ErrorResponse> {
  return await GET<TourGuide[]>("/guides")
}
