import React, { createContext, useContext, useEffect, useState } from "react"

import { useFirebase } from "./useFirebase"

import { Tour } from "../common/types"
import { createTour as apiCreateTour, getTours, getTour as apiGetTour, updateTour as apiUpdateTour} from "../common/api-client"

import { isLive, isUpcoming } from "../common/utils"
import Axios from "axios"

export interface ToursContextT {
  tours: Tour[]
  live: Tour[]
  upcoming: Tour[]
  singleTour?: Tour
  isLoading: boolean
  getTour?: (id: string) => void
  createTour (data: any, image: File, video: File, onUploadProgress: (i: number) => void): Promise<Tour>
  updateTour (id: string, data: any): any
  setSingleTour(newTour?: Tour): void
}

const initialState: ToursContextT = {
  tours: [],
  live: [],
  upcoming: [],
  isLoading: true,
  setSingleTour: ()=> {},
  updateTour: (id: string, data: any) => Promise.reject(),
  createTour: (data: any, image: File, video: File, onUploadProgress: (i: number) => void) => Promise.reject(),
}

export const Context = createContext<ToursContextT>(initialState)
export const useTours = () => useContext(Context)

export const ToursProvider: React.FC =
  ({ children }) => {
    const { user } = useFirebase()

    const [ tours, setTours ] = useState<Tour[]>([])
    const [ singleTour, setSingleTour ] = useState<Tour>();
    const [ isLoading, setLoading ] = useState<boolean>(true)

    const live = tours.filter(isLive)
    const upcoming = tours.filter(isUpcoming)

    useEffect(() => {
      // Need to be auth'd (even if anon) to make calls.
      if (!user) return

      getTours()
        .then(r => {
          setTours([...r])
          setLoading(false)
        })
        .catch(e => {
          console.error("Failed to get tours:", e)
          setLoading(false)
        })
    }, [ user ])

    const onProgress = (offset: number, onUploadProgress: (i: number) => void) =>
      (ev: ProgressEvent) => {
        const pc = (50 / ev.total) * ev.loaded
        onUploadProgress(offset + pc)
      }
    const getTour = (id: string) => {
      setSingleTour(undefined);
        apiGetTour(id)
          .then(r => {
            setSingleTour(r)
            setLoading(false)
          })
          .catch(e => {
            console.error("Failed to get tour:", e)
            setLoading(false)
          })
    }

  

    const createTour = async (data: any, image: File, video: File, onUploadProgress: (i: number) => void) => {
      try {
        setLoading(true)

        const tour = await apiCreateTour(data)
        await Axios.put((tour as any).signedPutImageUrl, image, {
          headers: { "Content-Type": image.type },
          onUploadProgress: onProgress(0, onUploadProgress),
        })
        await Axios.put((tour as any).signedPutVideoUrl, video, {
          headers: { "Content-Type": video.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          onUploadProgress: onProgress(50, onUploadProgress),
        })

        setTours(p => [...p, tour])
        setLoading(false)
        return tour
      } catch (e) {
        setLoading(false)
        return Promise.reject(e)
      }
    }

    const updateTour = async (id: string, data: any) => {
      try {
        setLoading(true)
        const update = await apiUpdateTour(id, data)
        // setLoading(false)
        //getTours after updating a tour
        getTours()
        .then(r => {
          setSingleTour(undefined)
          setTours([...r])
          setLoading(false)
        })
        .catch(e => {
          console.error("Failed to get tours:", e)
          setLoading(false)
        })
      } catch (e) {
        setLoading(false)
        return Promise.reject(e)
      }
    }

    return (
      <Context.Provider value={{ isLoading, tours, live, upcoming, createTour, singleTour, getTour, updateTour, setSingleTour }}>
        { children }
      </Context.Provider>
    )
  }
