import styled, { css } from "styled-components"

interface ButtonPropsT {
  compact?: boolean
  textOnly?: boolean
  secondary?: boolean
  disabled?: boolean
}

const Button = styled.button<ButtonPropsT>`
  margin: 0.5rem 1rem;
  padding: 0.7rem 2rem;

  font-size: 16px;
  font-weight: bold;

  ${ p => p.compact && css`
    padding: 0.4rem 1.5rem;
    font-size: 12px;
  `}

  border: none;
  border-radius: 0.5rem;

  color: white;
  cursor: pointer;

  outline: none;

  transition: 200ms background-color;

  :hover {
    ${ p => !p.textOnly && css`
      background-color: #cfbda5;
    `}
  }

  ${ p => p.textOnly && css`
    background: none;
    color: var(--theme-very-light-brown);
  `}

  ${ p => !p.textOnly && css`
    background-color: var(--theme-very-light-brown);
  `}

  ${ p => p.secondary && css`
    background-color: var(--theme-greeny-blue);
  `}

  ${ p => p.disabled && css`
    opacity: 0.4;
  `}
`

export default Button