import React from "react"
import { Tour } from "../../common/types"
import styled from "styled-components"
import { Tooltip } from "antd"
import { fmtDuration, isLive, isNew } from "../../common/utils"

const Root = styled.li`
  margin: 0.8rem 0;
  border-radius: 0.5rem;

  display: flex;

  color: white;
  background: var(--theme-dark-6);

  cursor: pointer;

  :hover {
    background: var(--theme-dusk);
  }

  transition: 0.750s background-color;
`
const Media = styled.div`
  position: relative;
  min-width: 90px;
  width: 90px;
`
const MediaImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
`
const VignetteEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.45rem;
  //box-shadow: inset 0 0 30px -8px black;
  z-index: 10;
`
const MediaDuration = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;

  border-radius: 0.3rem 0 0.45rem 0;

  padding: 6px 8px 4px 8px;
  font-size: 10px;
  font-weight: bold;
  display: block;
  background-color: var(--theme-dark-1);
  opacity: 0.8;
`
const NewLabel = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  border-radius: 0 0.3rem 0 0.45rem;

  padding: 6px 8px 4px 8px;
  font-size: 10px;
  font-weight: bold;
  display: block;
  background-color: var(--theme-clear-blue);
  color: white;
  text-align: center;
`
const LiveLabel = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;

  border-radius: 0.3rem 0 0.45rem 0;

  padding: 6px 8px 4px 8px;
  font-size: 8px;
  font-weight: bold;
  display: block;
  background-color: var(--theme-robins-egg-blue);
  color: white;
  text-align: center;
`
const TourInfo = styled.div`
  max-width: calc(100% - 90px - 1rem);
  padding: 20px 12px;
`
const TourTitle = styled.h5`
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const TourGuide = styled.span`
  display: inline-block;
  margin: 6px 0 0;
  font-size: 12px;
  font-weight: bold;
  color: var(--theme-very-light-brown);
`
const Detail = styled.div`
  margin: 12px 0 0;
`
const DetailLabel = styled.h6`
  margin: 6px 0;
  font-size: 12px;
  font-weight: 600;
`
const DetailContent= styled.span`
  font-size: 10px;
`

export interface TourListItemPropsT {
  item: Tour
  onClick? (): void
  owned?: boolean
}
const TourListItem: React.FC<TourListItemPropsT> =
  ({ item, onClick }) => {
    return (
      <Root onClick={onClick}>
        <Media>
          <MediaImage src={item.imageUrl} />

          { /* !isLive(item) && <MediaDuration>{ fmtDuration(item.tourDuration) }</MediaDuration> */ }
          { isLive(item) && <LiveLabel>LIVE</LiveLabel> }
          { isNew(item) && <NewLabel>New</NewLabel> }

          <VignetteEffect />
        </Media>

        <TourInfo>
          <Tooltip title={ item.name }>
            <TourTitle>{ item.name }</TourTitle>
          </Tooltip>
          <TourGuide><em>With</em> {!!item.guideName ? " " + item.guideName : " " + item.tourGuideSummary.firstName + " " + item.tourGuideSummary.lastName }</TourGuide>

          <Detail>
            <DetailLabel>Points of interest</DetailLabel>
            <DetailContent>{ item.tourLocation }</DetailContent>
          </Detail>
        </TourInfo>
      </Root>
    )
  }

export default TourListItem