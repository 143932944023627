import React from "react"

import { TourGuide } from "../../common/types"

import { List as BaseList } from "../List"

import styled from "styled-components"

import GuideListItemCompact from "./GuideListItemCompact"
import GuideListItemExpanded from "./GuideListItemExpanded"
import { useLayout } from "../Layout"

const Root = styled.div<{ expanded?: boolean }>`
  flex-grow: 1;
`
const Header = styled.h4`
  padding: 1rem 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--theme-light-blue-grey);
`
const List = styled(BaseList)<{ isPortrait: boolean, expanded?: boolean }>`
  display: flex;
  flex-direction: column;

  ${p => p.expanded && !p.isPortrait && `
    margin-top: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
  `};

  ${ p => !p.expanded && `
    > * {
      :first-child {
        margin-top: 0;
      }
    }
  `}
`

export interface GuideListItemPropsT {
  item: TourGuide
  onClick? (): void
}

interface GuideListPropsT {
  title?: string
  items: TourGuide[]
  expanded?: boolean
  noscroll?: boolean
  onItemClick? (it: TourGuide): void
}
const GuideList: React.FC<GuideListPropsT> =
  ({ title, items, expanded, onItemClick }) => {
    const { isPortrait } = useLayout()

    const ListItemComponent = !expanded ? GuideListItemCompact : GuideListItemExpanded

    return (
      <Root>
        { title && <Header>{ title }</Header> }

        <List expanded={expanded} isPortrait={isPortrait}>
          {
            items.map(
              it =>
                <ListItemComponent
                    key={it.id}
                    item={it}
                    onClick={() => onItemClick ? onItemClick(it) : undefined} />
            )
          }
        </List>
      </Root>
    )
  }

export default GuideList