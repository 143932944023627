import React from "react"

import { useFirebase } from "../../hooks/useFirebase"

import BaseButton from "../Button"

import styled from "styled-components"
import { Modal } from "antd"

import AppleLogo from "../../images/icons/apple.svg"
import GoogleLogo from "../../images/icons/google.svg"

const Root = styled(Modal)``
const Main = styled.div`
  height: 455px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const OrLabel = styled.span`
  margin: 1rem;
  font-size: 16px;
  font-weight: bold;
  color: white;
`

const Button = styled(BaseButton)`
  padding: 0.5rem 0;
  width: 217px;
  background-size: cover;
`

const SignInButton = styled(Button)`
  height: 40px;
  width: 200px;
  padding-left: calc(20px + 2rem);

  font-size: 14px;
  font-weight: bold;

  text-align: left;

  background-repeat: no-repeat !important;
  background-size: 20px !important;
  background-position: 1rem 50% !important;
`
const GoogleSignInButton = styled(SignInButton)`
  background: white;
  background-image: url(${GoogleLogo});
  background-position-y: calc(50% + 1px) !important;
  :hover { background-color: white; }

  color: black;
`
const AppleSignInButton = styled(SignInButton)`
  background: black;
  background-image: url(${AppleLogo});
  background-position-y: calc(50% - 2px) !important;
  :hover { background-color: black; }

  color: white;
`
const ContinueAnonButton = styled(Button)`
  margin: 0;
  padding: 0;
`
ContinueAnonButton.defaultProps = { textOnly: true }

interface SignInDialogPropsT {
  onClose (): void
}
const SignInDialog: React.FC<SignInDialogPropsT> =
  ({ onClose }) => {
    const {
      signInWithApple,
      signInWithGoogle,
      signInAnonymously,
    } = useFirebase()

    const createAction = (signInFunc: () => void) => async () => {
      await signInFunc()
      onClose()
    }

    return (
      <Root visible={true} footer={null} closable={false}>
        <Main>
          <GoogleSignInButton onClick={ createAction(signInWithGoogle) }>Sign in with Google</GoogleSignInButton>
          <AppleSignInButton onClick={ createAction(signInWithApple) }>Sign in with Apple</AppleSignInButton>

          <OrLabel>Or</OrLabel>

          <ContinueAnonButton onClick={ createAction(signInAnonymously) }>Continue Anonymously</ContinueAnonButton>
        </Main>
      </Root>
    )
  }

export default SignInDialog