import React, { useEffect, useRef } from "react"
import { Tour } from "../../common/types"
import styled from "styled-components"

const Root = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  background-color: black;
`
const Video = styled.video`
  position: absolute;
  top:0; right:0; bottom:0; left:0;
  width: 100%;
  height: 100%;
  outline: none;
`
const Controls = styled.div`
  display: absolute;
  top:0; right:0; bottom:0; left:0;
`

interface VideoPlayerPropsT {
  posterUrl: string

  videoType: string
  videoUrl: string
  tour: Tour
  autoPlay?: boolean

  onVideoEnded? (): void
}
const VideoPlayer: React.FC<VideoPlayerPropsT> =
  ({ posterUrl, videoType, videoUrl, autoPlay, onVideoEnded, tour }) => {
    const video = useRef<HTMLVideoElement>(null)
    const videoSrc = useRef<HTMLSourceElement>(null)
    useEffect(() => {
      if(!video.current) return

      const onEnded = () => { if (onVideoEnded) onVideoEnded() }

      const el = video.current
      el.addEventListener("ended", onEnded)

      return () => el.removeEventListener("ended", onEnded)
    }, [ video, onVideoEnded ])

    useEffect(() => {
      if (!video.current || !videoSrc.current) return

      videoSrc.current.src = !!tour.streamVideoStatus && tour.streamVideoStatus === "Ready" ? videoSrc.current.src = tour.streamVideoDetails.hlsUrl : videoUrl
      video.current.load()
    }, [ videoUrl ])
    console.log(videoSrc);
    return (
      <Root>
        <Video ref={video} controls preload="metadata" controlsList="nodownload" poster={posterUrl} autoPlay={autoPlay}>
          <source ref={videoSrc} src={
            !!tour && tour.streamVideoStatus === "Ready" ?
            tour.streamVideoDetails.hlsUrl :
            videoUrl
            }
            type={videoType} />
        </Video>

        <Controls>
        </Controls>
      </Root>
    )
  }

export default VideoPlayer