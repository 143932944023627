import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"

import dayjs, { Dayjs } from "dayjs"
import moment, { Moment } from "moment"

import styled from "styled-components"

import { TourType } from "../../common/types"
import { useTours } from "../../hooks/useTours"

import Button from "../../components/Button"
import Switch from "../../components/Switch"
import { DatePicker } from "../../components/DatePicker"
import { TagInput } from "../../components/TagInput"
import { TextInput } from "../../components/TextInput"
import { TextArea } from "../../components/TextArea"
import { TimeInput } from "../../components/TimeInput"

import {
  Section,
  SectionTitle,
  SectionSubtitle,
  SectionDescription,
  FormFields,
  SectionList, SectionListItem,
} from "../../components/FormCommon"

import { MediaUploadSection } from "../../components/TourUpload/MediaUpload"
import { LocationSearchSection } from "../../components/TourUpload/LocationSearch"

import { message } from "antd"
import { CheckOutlined } from "@ant-design/icons"

import CloseIcon from "../../images/icons/close.png"
import { CountryToContinent } from "../../common/continents"

interface FormDataT {
  id?: string

  name: string

  firstName: string
  lastName: string

  tourType: TourType

  tourDescripition: string
  tourDuration: string

  tourLocation: string

  point: string
  continent: string
  country: string
  region: string
  city: string
  tourAddress: string

  aspectRatioWidth: string
  aspectRatioHeight: string

  tags: string

  is360: boolean

  videoFileName: string
  imageFileName: string

  publishDate: Moment
  publishTime: Dayjs

  locationType: string

  tourMinutes: string
  tourSeconds: string
}

const Root = styled.div`
  flex-grow: 1;
  display: flex;
`
const Main = styled.div`
  max-height: 100vh;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  z-index: 10;
  border-bottom: 1px solid var(--theme-border-color);
  box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.2);
`
const HeaderContent = styled.div`
  max-width: 80rem;

  padding: 35px 45px;
  display: flex;
`
const HeaderText = styled.h2`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
`
const CloseButton = styled.button`
  width: 55px;
  height: 55px;
  border: none;
  border-radius: 50%;
  background: rgba(216, 216, 216, 0.26) url(${CloseIcon}) no-repeat;
  background-position: 50% 50%;
  background-size: 26px 26px;
  outline: none;
`
const Sidebar = styled.div`
  padding: 125px 0 0 0;
  width: 280px;
  min-width: 280px;
  background-color: var(--theme-dark-1);
`
const Form = styled.form`
  margin-right: 15px;
  padding: 42px 15px 8px 45px;

  max-width: 80rem;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar { width: 5px; }
  ::-webkit-scrollbar-track { }
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-dark-6);

    :hover {
      background-color: var(--theme-dusk);
    }
  }
`
const FormActions = styled.div`
  border-top: 1px solid var(--theme-border-color);
  background-color: var(--theme-dark-2);
`
const FormActionItems = styled.div`
  padding: 26px 45px;

  max-width: 80rem;

  display: flex;
  flex-direction: row-reverse;
`
const SubmitButton = styled(Button)`
  margin: 0;
  padding: 24px 74px;
  font-size: 16px;
`
const UploadProgressIndicator = styled.div<{ progress: number }>`
  position: absolute;
  left: 0; bottom: 0;
  height: 4px;
  width: ${p => p.progress}%;
  background-color: var(--theme-light-aquamarine);

  transition: width 150ms;
`

const SECTION_INFO = [
  ["Media", "media"],
  ["Tour Guide", "tourGuide"],
  ["Title & Description", "title"],
  ["Location", "location"],
  ["Tags", "tags"],
  ["Details", "details"]
]

const HIDDEN_FIELDS = [
  "id",
  "tourType",
  "region",
]

const HIDDEN_REQUIRED_FIELDS = [
  "videoFileName",
  "imageFileName",
  "tourDuration",
  "point",
  "continent",
  "country",
  "city",
  "tourAddress",

  "locationType",
]

export const UploadPage: React.FC =
  () => {
    const history = useHistory()

    const { createTour } = useTours()

    const formRef = useRef<HTMLFormElement>(null)

    const [ isWorking, setWorking ] = useState<boolean>(false)

    // Sections & navigation state
    const [ activeSectionIndex, setActiveSectionIndex ] = useState<number>(0)
    const [ sectionRefs ] = useState<React.RefObject<HTMLDivElement>[]>(
      new Array(SECTION_INFO.length)
        .fill(null)
        .map((_, i) => React.createRef<HTMLDivElement>())
    )
    const [ sectionStates, setSectionStates ] = useState<boolean[]>(
      new Array(SECTION_INFO.length).fill(false)
    )

    //
    const form = useForm<FormDataT>({
      mode: "onBlur",
      defaultValues: {
        tourType: TourType.Video,
        name: "",
        firstName: "",
        lastName: "",
        tourDescripition: "",
        tourLocation: "",
        tourDuration: "",
        aspectRatioWidth: "1920",
        aspectRatioHeight: "1080",
        is360: false,
        publishDate: moment(),
        publishTime: dayjs(),
        tags: "",
        tourMinutes: "0",
        tourSeconds: "0",
      }
    })
    const { control, errors, handleSubmit, register, setValue, watch } = form
    // File upload state
    const [ videoFile, setVideoFile ] = useState<File | null>(null)
    const [ imageFile, setImageFile ] = useState<File | null>(null)
    const [ uploadProgress, setUploadProgress ] = useState<number>(0)

    // Location search state
    const [ searchTarget, setSearchTarget ] = useState<any | null>(null)

    const {
      name,
      tourDescripition: description,
      publishDate,
      publishTime,
      tags,
      tourDuration,
      locationType,
      city: locationCity,
      tourAddress: locationProperty,
      tourMinutes,
      tourSeconds,
      firstName,
      lastName,
      tourLocation,
    } = watch(["name", "firstName", "lastName", "tourDescripition", "tourLocation", "publishDate", "publishTime", "tags", "tourDuration", "locationType", "city", "tourAddress", "tourMinutes", "tourSeconds"])

    const scrollToSection = useCallback((i: number) => {
      const form = formRef.current
      const section = sectionRefs[i].current
      if (!form || !section) return

      const scrollY = section.offsetTop - form.offsetTop - 42
      const offsetY = form.clientHeight / 2 - section.clientHeight / 2
      form.scrollTo(0, scrollY - offsetY + (20 * i))
      setActiveSectionIndex(i)
    }, [ sectionRefs ])

    // Scroll to top if we're missing asset uploads.
    useEffect(() => {
      if (!scrollToSection || !errors) return

      console.warn(errors);

      if (!!errors.videoFileName || !!errors.imageFileName) {
        message.warn("You must upload a video and cover photo.")
        formRef.current?.scrollTo(0, 0)
        return
      }

      if (!!errors.name || !!errors.tourDescripition) {
        message.warn("You must specify a title and description.")
        scrollToSection(1)
        return
      }

      if (!!errors.city) {
        message.warn("you must specify a location city.");
        scrollToSection(2)
        return
      }
    }, [errors, locationType, scrollToSection])

    useEffect(() => {
      const mins = parseInt(tourMinutes) || 0;
      const secs = parseInt(tourSeconds) || 0;
      setValue("tourDuration", (mins * 60) + secs);
    }, [setValue, tourMinutes, tourSeconds]);

    // Update section completion state.
    useEffect(() => {
      const sectionStates: boolean[] = [ !!(imageFile && videoFile) ]
      sectionStates.push(!!locationType && !!locationCity && !!locationProperty)
      sectionStates.push(tags.length > 0)
      sectionStates.push(parseInt(tourDuration) > 0)
      setSectionStates(sectionStates)
    }, [
      imageFile, videoFile,
      name, description,
      firstName, lastName,
      locationType,
      locationCity, locationProperty,
      tags,
      tourDuration,
    ])

    useEffect(() => {
      if (!setValue || !searchTarget) return
      if (!searchTarget.address_components) {
        return;
      }
      const country = searchTarget.address_components.find((it: any) => it.types.includes("country"))
      const continent = CountryToContinent[country.short_name]
      const region = searchTarget.address_components.find((it: any) => it.types.includes("administrative_area_level_1"))
      const city = searchTarget.address_components.find((it: any) => it.types.includes("locality"))

      setValue("locationType", searchTarget.types.join(", "))
      setValue("point", `${searchTarget.geometry.location.lat()}, ${searchTarget.geometry.location.lng()}`)
      setValue("continent", continent)
      setValue("country", country ? country.short_name : "")
      setValue("region", region ? region.long_name : (country ? country.long_name : ""))
      if (!city) {
        message.warn("You must specify a location city.");
      }
      setValue("city", city ? city.long_name : "")

      if (!searchTarget.formatted_address) {
        message.warn("You must specify a property location.");
      }
      setValue("tourAddress", searchTarget.formatted_address)
    }, [setValue, searchTarget])

    const onFormScrollEvent = (ev: React.UIEvent<HTMLFormElement, Event>) => {
      const form = ev.target as HTMLFormElement

      const ppct = (form.scrollHeight - form.clientHeight) / 100
      const spct = form.scrollTop / ppct

      const index = sectionRefs.findIndex(
        (it, i) => {
          if (!it.current) return true
          return (100 / SECTION_INFO.length) * (i + 1) > spct
        }
      )
      setActiveSectionIndex(index)
    }

    const onFileSelected = (upload: File) => {
      const { type } = upload

      if (type.startsWith("video/")) {
        setVideoFile(upload)
        setValue("videoFileName", upload.name)
      } else if (type.startsWith("image/")) {
        setImageFile(upload)
        setValue("imageFileName", upload.name)
      } else {
        console.warn("Unknown file type upload attempted:", upload)
      }
    }

    const doSubmit = async (data: FormDataT) => {
      if (!videoFile || !imageFile) return

      const {
        name,
        tourType,
        tourDescripition,
        tourDuration,
        tourLocation,
        point,
        continent,
        country,
        region,
        city,
        tourAddress,
        aspectRatioWidth,
        aspectRatioHeight,
        is360,
        publishDate,
        publishTime,
        tags,
        firstName,
        lastName,
      } = data

      const date = dayjs(publishDate.toISOString())
        .hour(publishTime.hour())
        .minute(publishTime.minute())
        .second(0)

      const details = {
        name,

        tourType,
        tourDescripition,
        tourDuration: parseInt(tourDuration),

        aspectRatioWidth: parseInt(aspectRatioWidth),
        aspectRatioHeight: parseInt(aspectRatioHeight),
        guideName: !!firstName && !!lastName ? firstName + " " + lastName : "",
        is360,
        tags: data.tags.replace(/[^a-zA-Z ]/g, "").split(" "),
        date,

        imageMediaData: {
          contentType: imageFile.type
        },

        videoMediaData: {
          contentType: videoFile.type
        },

        tourLocation,
        point,
        continent,
        country,
        region,
        city,
        tourAddress,
      }
      const onUploadProgress = (pc: number) => {
        setUploadProgress(pc)

        if (Math.ceil(pc) >= 100) {
          setTimeout(() => setUploadProgress(-1))
          setSectionStates(p => p.map((v, i) => i === 0 ? true : v))
        }
      }

      try {
        setWorking(true)
        const tour = await createTour(details, imageFile, videoFile, onUploadProgress)
        message.success("Tour created successfully!")
        setWorking(false)
        history.replace(`/tours/${tour.id}`)
      } catch (e) {
        message.error("Submission Failed, please try again.")
        setWorking(false)
        console.error(e)
      }
    }

    const onSubmit = handleSubmit(doSubmit);
    return (
      <Root>
        <Sidebar>
          <SectionList>
            { SECTION_INFO.map(
                ([it], i) =>
                  <SectionListItem key={i}
                      isActive={activeSectionIndex === i}
                      onClick={() => scrollToSection(i)}>
                    <span>{ it }</span>
                    { sectionStates[i] && <CheckOutlined />}
                    { i === 0 && uploadProgress !== -1 &&
                      <UploadProgressIndicator progress={uploadProgress} />
                    }
                  </SectionListItem>
              )
            }
          </SectionList>
        </Sidebar>

        <Main>
          <Header>
            <HeaderContent>
              <HeaderText>Create Your Tour</HeaderText>
              <CloseButton onClick={() => history.goBack()} />
            </HeaderContent>
          </Header>

          <Form ref={formRef}
              onKeyPress={ev => ev.key === "Enter" && ev.preventDefault()}
              onScroll={onFormScrollEvent}
              onSubmit={onSubmit}>

            { HIDDEN_FIELDS.map(
                it => <input key={it} type="hidden" name={it} ref={register} />
              )
            }
            { HIDDEN_REQUIRED_FIELDS.map(
                it => <input key={it} type="hidden" name={it} ref={register({ required: true })} />
              )
            }

            <MediaUploadSection ref={sectionRefs[0]}
                imageFile={imageFile}
                videoFile={videoFile}
                onFileSelected={onFileSelected} />

            <Section ref={sectionRefs[1]}>
              <SectionTitle>Tour Guide</SectionTitle>
              <SectionDescription>
                Add name of Tour Guide!
              </SectionDescription>
              <FormFields>
                <TextInput ref={register({ required: false })}
                    invalid={"firstName" in errors}
                    name="firstName"
                    placeholder="First Name" />
                <TextInput ref={register({ required: false })}
                  invalid={"lastName" in errors}
                  name="lastName"
                  placeholder="Last Name" />
              </FormFields>
            </Section>

            <Section ref={sectionRefs[2]}>
              <SectionTitle>Title &amp; Description</SectionTitle>
              <SectionDescription>
                This is the first introduction to your tour. Make sure people understand what they will see and where they are going!
              </SectionDescription>
              <FormFields column>
                <TextInput ref={register({ required: true })}
                    invalid={"name" in errors}
                    name="name"
                    placeholder="Title" />

                <TextArea ref={register({ required: true })}
                    invalid={"tourDescripition" in errors}
                    name="tourDescripition"
                    placeholder="Description, think about what people will see and learn." />
              </FormFields>
            </Section>
            
            <Section ref={sectionRefs[3]}>
            <SectionTitle>Location</SectionTitle>
            <SectionDescription>
                Enter a name for this location. For example: "Work", or "Home City"
              </SectionDescription>
              <FormFields column>
                <TextInput ref={register({required: true})} invalid={false} name="tourLocation" />
               </FormFields>
            <LocationSearchSection
                title="Address"
                invalid={ "city" in errors || "tourAddress" in errors}
                value={searchTarget}
                onChange={setSearchTarget} />
            </Section>
 

            <Section ref={sectionRefs[4]}>
              <SectionTitle>Tags</SectionTitle>
              <SectionSubtitle>Tag your tour</SectionSubtitle>
              <SectionDescription>This helps people find your tour, based on the things that interest them</SectionDescription>
              <FormFields column>
                <TextInput ref={register({ required: false })}
                    invalid={"tags" in errors}
                    name="tags"
                    placeholder="Add Tags for video..." />
              </FormFields>
            </Section>

            <Section ref={sectionRefs[5]}>
              <SectionTitle>Details</SectionTitle>
              <SectionDescription>
                Enter the time and date when your tour was recorded.
              </SectionDescription>

              <FormFields>
                <Controller
                    name="publishDate"
                    rules={{ required: true }}
                    defaultValue={publishDate}
                    control={control}
                    render={(props) =>
                      <DatePicker inputReadOnly allowClear={false}
                          {...props}
                          placeholder="Publish Date" />
                    }
                    />
                <Controller
                    name="publishTime"
                    rules={{ required: true }}
                    defaultValue={publishTime}
                    control={control}
                    render={(props) =>
                      <TimeInput {...props} />
                    } />
              </FormFields>

              <SectionTitle>Tour Duration</SectionTitle>
              <FormFields>
                <TextInput type="number" ref={register({ required: true })}
                  invalid={"tourDuration" in errors}
                  name="tourMinutes"
                  placeholder="Mins" />
                <span>mins</span>
                <TextInput type="number" ref={register({ required: true })}
                  invalid={"tourDuration" in errors}
                  name="tourSeconds"
                  placeholder="Secs" />
                <span>secs</span>
              </FormFields>

              <SectionTitle>Video Dimensions</SectionTitle>
              <FormFields>
                <TextInput name="aspectRatioWidth" placeholder="Width" ref={register({ required: true }) } />
                x
                <TextInput name="aspectRatioHeight" placeholder="Height" ref={register({ required: true }) } />
              </FormFields>

              <FormFields>
                <SectionTitle>Is 360&deg; Panoramic</SectionTitle>
                <Controller
                    name="is360"
                    control={control}
                    render={(props) => <Switch {...props} />} />
              </FormFields>
            </Section>
          </Form>

          <FormActions>
            <FormActionItems>
              <SubmitButton disabled={isWorking} onClick={() => onSubmit()}>
                { isWorking ? "Uploading..." : "Publish" }
              </SubmitButton>
            </FormActionItems>
          </FormActions>
        </Main>
      </Root>
    )
  }