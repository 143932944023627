import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import styled from "styled-components"

interface TimeInputPropsT {
  name?: string
  placeholder?: string
  defaultValue?: Dayjs
  onChange? (v: Dayjs): void
}

const Root = styled.div`
  padding: 12px 28px;

  display: flex;
  align-items: center;

  font-size: 16px;
  border: none;
  border-radius: 16px;
  background-color: var(--theme-dark-3);
  outline: none;

  input {
    margin: 0;
    padding: 0;
    width: 2rem;
    background: none;
    border: none;
    outline: none;

    :first-of-type {
      text-align: right;
    }
  }

  select {
    margin: 0;
    padding: 0;
    background: none;
  }
`

export const TimeInput = React.forwardRef<HTMLElement, TimeInputPropsT>(
  ({ defaultValue, onChange }, ref) => {
    const [ hour, setHour ] = useState<number>(parseInt(defaultValue?.format("h") || dayjs().format("h")))
    const [ mins, setMins ] = useState<number>(defaultValue?.minute() || dayjs().minute())
    const [ isAm, setAm ] = useState<boolean>((defaultValue?.hour() || dayjs().hour()) < 12)

    useEffect(() => {
      if (!onChange) return
      const value = dayjs().hour(hour + (isAm ? 0 : 12)).minute(mins).second(0)
      onChange(value)
    }, [ onChange, hour, mins, isAm ])

    const onHourChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const v = parseInt(ev.target.value)
      if (isNaN(v)) return setHour(1)
      setHour(v > 12 ? 12 : v)
    }

    const onMinsChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const v = parseInt(ev.target.value)
      if (isNaN(v)) return setMins(0)
      setMins(v > 59 ? 59 : v)
    }

    const onHourKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "ArrowDown") {
        setHour(p => p - 1 < 1 ? 1 : p - 1)
        ev.preventDefault()
      } else if (ev.key === "ArrowUp") {
        setHour(p => p + 1 > 12 ? 12 : p + 1)
        ev.preventDefault()
      }

      return
    }

    const onMinsKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "ArrowDown") {
        setMins(p => p - 1 < 0 ? 0 : p - 1)
        ev.preventDefault()
      } else if (ev.key === "ArrowUp") {
        setMins(p => p + 1 > 59 ? 59 : p + 1)
        ev.preventDefault()
      }

      return
    }

    return (
      <Root>
        <input type="text" value={("00" + hour).slice(-2)}
            pattern="[0-1]?[0-9]"
            onChange={ev => onHourChange(ev)}
            onKeyDown={onHourKeyDown} />
        :
        <input type="text" value={("00" + mins).slice(-2)}
            pattern="[0-5][0-9]"
            onChange={ev => onMinsChange(ev)}
            onKeyDown={onMinsKeyDown} />

        <select value={isAm ? "am" : "pm"} onChange={(ev) => setAm(ev.target.value === "am")}>
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </Root>
    )
  }
)