import React from "react"
import styled from "styled-components"

import { GuideListItemPropsT } from "."

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemPrimaryText,
} from "../List"

import AvatarPlaceholder from "../../images/icons/avatar-placeholder.png"

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

const GuideListCompactItem: React.FC<GuideListItemPropsT> =
  ({ item, onClick }) => (
    <ListItem onClick={onClick}>
      <ListItemIcon>
        <ProfileImage src={item.imageUrl || AvatarPlaceholder} />
      </ListItemIcon>

      <ListItemText>
        <ListItemPrimaryText>{item.firstName} {item.lastName}</ListItemPrimaryText>
      </ListItemText>
    </ListItem>
  )

export default GuideListCompactItem