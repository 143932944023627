import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"

import { Tour } from "../../common/types"

import { fmtTimeFromNow, isLive } from "../../common/utils"

import { useFirebase } from "../../hooks/useFirebase"
import { useUserProfile } from "../../hooks/useUserProfile"
import { useTourGuides } from "../../hooks/useTourGuides"
import { useTours } from "../../hooks/useTours"

import { useLayout } from "../../components/Layout"

import Button from "../../components/Button"
import Switch from "../../components/Switch"

import TourList from "../../components/TourList"
import VideoPlayer from "../../components/VideoPlayer"

import styled from "styled-components"

import TourPlaceholder from "../../images/icons/tour-placeholder.png"
//import ShareIcon from "../../images/icons/share.png"
//import TipIcon from "../../images/icons/tip.png"

const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    height: auto;
  }
`
const Main = styled.div`
  position: relative;
  margin-left: 2rem;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  margin-right: 0.5rem;
  padding-right: 0.5rem;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar { width: 5px; }
  ::-webkit-scrollbar-track { }
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-dark-6);

    :hover {
      background-color: var(--theme-dusk);
    }
  }

  @media (max-width: 1000px) {
    position: relative;
    margin: 0;
    padding: 0 2rem 0 0;
    width: 100%;
  }
`
const Sidebar = styled.div`
  min-width: 360px;
  height: 100%;

  @media (max-width: 1000px) {
    height: auto;
  }

  display: flex;
  flex-direction: column;
`
const ListContainer = styled.div`
  position: relative;
  padding: 0 0.5rem 0 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
const GuideHead = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(228, 228, 228, 0.1);
`
const ProfileImage = styled.img`
  margin-right: 1.5rem;
  width: 62px;
  height: 62px;
  border-radius: 1rem;
`
const GuideInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin: 0.2rem 0;
  }
`
const GuideName = styled.h4`
  font-size: 16px;
  font-weight: bold;
`
const ListHeader = styled.div`
  padding: 1rem 1rem 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ListTitle = styled.h4`
  flex-grow: 1;
  font-size: 18px;
  font-weight: medium;
  color: white;
`
const TourDetails = styled.div`
  position: relative;
  padding: 1rem 0 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid rgba(228, 228, 228, 0.1);
`
const TourTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;
`
const TourGuide = styled.h3`
  display: inline;
  font-size: 16px;
  font-weight: bold;
  color: var(--theme-very-light-brown);
  opacity: 0.74;
`
const TourViewers = styled.span`
  margin: 0.5rem 0 0 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--theme-blue-grey);
`
const UserActions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`
const UserActionItem = styled(Button)<{icon: string}>`
  margin: 0 1rem; padding: 0.5rem 0 0.5rem 34px;
  font-size: 14px;

  background-image: url(${ p => p.icon });
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
`
const Description = styled.div`
  padding: 2rem 0.5rem;
`
const Heading = styled.h5`
  font-size: 16px;
  font-weight: bold;
  color: white;
  opacity: 0.74;
`
const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: white;
  opacity: 0.74;
`

interface ParamsT {
  tourId: string
}
const TourViewPage: React.FC =
  () => {
    const { tourId } = useParams<ParamsT>()
    const history = useHistory()

    const { user } = useFirebase()
    const profile = useUserProfile()

    const { isPortrait } = useLayout()

    const { tours } = useTours()
    const { guides } = useTourGuides()

    const [ tour, setTour ] = useState<Tour | null>(tours.find(it => it.id === tourId) || null)

    const [ autoPlay, setAutoPlay ] = useState<boolean>(true)

    const guide = guides.find(it => it.id === tour?.userId)
    const isFollowing = !!profile.following.find(it => it.id === guide?.id)

    const nextIndex = tours.findIndex(it => it.id === tourId) + 1
    const queue = tours.slice(nextIndex)

    useEffect(() => {
      setTour(
        tours.find(it => it.id === tourId) || null
      )
    }, [ tours, tourId ])

    const onVideoEnded = () => {
      const next = tours[nextIndex]
      if (!autoPlay || !next) return
      return history.push(`/tours/${next.id}`)
    }

    if (!tour) return <></>

    console.info(tour)

    return (
      <Root>
        <Sidebar>
          { !isPortrait &&
            <GuideHead>
              <Link to={tour.userId ? `/guides/${tour.userId}` : `/guides/${tour.tourGuideId}`}>
                <ProfileImage src={ tour.tourGuideSummary.imageUrl } />
              </Link>
              <GuideInfo>
                <Link to={tour.userId ? `/guides/${tour.userId}` : `/guides/${tour.tourGuideId}`}>
                  <GuideName>{ tour.tourGuideSummary.firstName } { tour.tourGuideSummary.lastName }</GuideName>
                </Link>

                { profile.isLoading &&
                  <Button compact textOnly>Loading...</Button>
                }
                { !profile.isLoading && !user?.isAnonymous &&
                  <Button compact secondary={!isFollowing} onClick={() => profile.follow(tour.userId)}>
                    { isFollowing ? "Following" : "Follow" }
                  </Button>
                }
              </GuideInfo>
            </GuideHead>
          }

          <ListContainer>
            <ListHeader>
              <ListTitle>Up Next</ListTitle>

              <Switch checked={autoPlay} onChange={v => setAutoPlay(v)}/>
            </ListHeader>

            <TourList
                items={ queue }
                noscroll={isPortrait}
                onItemClick={it => history.push(`/tours/${it.id}`)} />
          </ListContainer>
        </Sidebar>

        <Main>
          <Container onContextMenu={(e)=>  {e.preventDefault(); return false;}}>
            <VideoPlayer autoPlay={autoPlay}
                posterUrl={tour.imageUrl || TourPlaceholder}
                videoType={tour.videoMediaData?.contentType || ""}
                videoUrl={tour.videoUrl}
                tour={tour}
                onVideoEnded={onVideoEnded} />

            <TourDetails>
              <TourTitle>{ tour.name }</TourTitle>
              <Link to={`/guides/${ tour.userId }`}>
                <TourGuide><em>With</em>
                  {!!tour.guideName ? " " + tour.guideName : " " + tour.tourGuideSummary.firstName + " " + tour.tourGuideSummary.lastName }
                </TourGuide>
              </Link>

              { isLive(tour) &&
                <TourViewers>{ tour.stats.currentConnectionCount } People are watching with you</TourViewers>
              }
              { !isLive(tour) &&
                <TourViewers>{/* "230 views | " */}{ fmtTimeFromNow(tour.date) }</TourViewers>
              }

              <UserActions>
                { /*
                <UserActionItem textOnly icon={TipIcon} style={{ color: "white" }}>SEND TIP</UserActionItem>
                <UserActionItem textOnly icon={ShareIcon} style={{ color: "white" }}>SHARE</UserActionItem>
                */ }
              </UserActions>
            </TourDetails>

            <Description>
              <Heading>About</Heading>
              <Text>{ tour.tourDescripition }</Text>
            </Description>
          </Container>
        </Main>
      </Root>
    )
  }

export default TourViewPage