import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import { Controller, useForm } from "react-hook-form"

import { UserProfile } from "../../common/types"

import { useUserProfile } from "../../hooks/useUserProfile"

import { useImageUpload } from "../../hooks/useFileUpload"
import useScaleImage from "../../hooks/useScaleImage"

import Button from "../../components/Button"

import Switch from "../../components/Switch"
import { TextInput } from "../../components/TextInput"
import { TextArea } from "../../components/TextArea"

import { LocationSearchSection } from "../../components/TourUpload/LocationSearch"

import {
  Section,
  SectionTitle,
  SectionDescription,
  FormFields,
} from "../../components/FormCommon"

import {
  Root,
  Container,
  Header,
  ProfileInfo,
} from "../../components/UserProfile"

import styled from "styled-components"
import { message } from "antd"

import AddIcon from "../../images/icons/add.png"

interface FormDataT {
  id: string

  firstName: string
  lastName: string
  handle: string

  bio: string

  homeLocation: string
  homeLocationGeo: string

  isPrivate: boolean
}

const Form = styled.form`
  margin-right: 15px;
  padding: 42px 15px 8px 45px;

  max-width: 80rem;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar { width: 5px; }
  ::-webkit-scrollbar-track { }
  ::-webkit-scrollbar-thumb {
    background-color: var(--theme-dark-6);

    :hover {
      background-color: var(--theme-dusk);
    }
  }
`

const FieldLabel = styled.label`
`

const ImageUploadSection = styled(Section)`
  display: flex;
  align-items: flex-start;
`

const ImageUploadSectionDetails = styled.div`
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
`

const ImageUploadRoot = styled.div`
  position: relative;

  width: 10rem;
  height: 10rem;

  border: none;
  border-radius: 16px;

  font-size: 16px;

  background-color: var(--theme-dark-3);

  cursor: pointer;

  img {
    :first-of-type {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    :last-of-type {
      position: absolute;

      bottom: 8px; right: 8px;

      width: 32px;
      height: 32px;
    }
  }
`

interface ImageUploadInputPropsT {
  defaultValue?: string
  onChange (target: any): void
}

const ImageUpload: React.FC<ImageUploadInputPropsT> =
  ({ defaultValue, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { image, onFileInputChange } = useImageUpload();
    const [ preview, value ] = useScaleImage(image, 512);

    useEffect(() => {
      if (!onChange) return;
      onChange(value);
    }, [ onChange, value ]);

    return (
      <ImageUploadRoot onClick={() => inputRef.current?.click()}>
        <input type="file" style={{ display: "none" }} ref={inputRef} onChange={onFileInputChange} />

        { (preview || defaultValue) && <img src={preview || defaultValue} alt="Profile" /> }

        <img src={AddIcon} alt="Add" />
      </ImageUploadRoot>
    )
  }

const ProfileEditPage: React.FC =
  () => {
    const history = useHistory();
    const profile = useUserProfile();

    const [ searchTarget, setSearchTarget ] = useState<any | null>(null)

    const form = useForm<FormDataT>({
      defaultValues: {
        id: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        handle: profile.handle,
        bio: profile.bio,
        homeLocation: profile.homeLocation,
        isPrivate: profile.isPrivate || false,
      }
    });
    const { control, errors, handleSubmit, register, setValue } = form;

    useEffect(() => {
      if (!profile || !setValue) return;

      setValue("id", profile.id);
      setValue("firstName", profile.firstName);
      setValue("lastName", profile.lastName);
      setValue("handle", profile.handle);
      setValue("bio", profile.bio);
      setValue("homeLocation", profile.homeLocation);
      setValue("isPrivate", profile.isPrivate || false);
    }, [ profile, setValue ])

    useEffect(() => {
      if (!searchTarget) return;

      const city = searchTarget.address_components.find((it: any) => it.types.includes("locality"));
      setValue("homeLocation", city ? city.long_name : "");
      setValue("homeLocationGeo", `${searchTarget.geometry.location.lat()}, ${searchTarget.geometry.location.lng()}`);
    }, [searchTarget, setValue]);

    const onImageUpload = (image: Blob | null) => {
      if (!image) return;
      console.info(image);
      profile.setProfileImage(image);
    }

    const doSubmit = async (data: FormDataT) => {
      console.info(data);
      await profile.update(data as UserProfile);
      message.success("Profile updated successfully!");
      history.goBack();
    }

    const onSubmit = handleSubmit(doSubmit);

    return (
      <Root>
        <Container>
          <Header>
            <ProfileInfo />
            <Button onClick={() => onSubmit()}>Save Changes</Button>
          </Header>

          <Form onSubmit={onSubmit}>
            <input type="hidden" name="homeLocation" ref={register} />
            <input type="hidden" name="homeLocationGeo" ref={register} />

            <ImageUploadSection>
              <ImageUpload defaultValue={profile.imageUrl} onChange={onImageUpload} />

              <ImageUploadSectionDetails>
                <SectionTitle>Profile Image</SectionTitle>
                <SectionDescription>Upload/update your profile image.</SectionDescription>
              </ImageUploadSectionDetails>
            </ImageUploadSection>

            <Section>
              <SectionTitle>Personal Information</SectionTitle>

              <FormFields column style={{ width: "20rem" }}>
                <TextInput name="firstName"
                    placeholder="First name"
                    invalid={"firstName" in errors}
                    ref={register({ required: true })} />

                <FieldLabel htmlFor="firstName">
                </FieldLabel>

                <TextInput
                    name="lastName"
                    placeholder="Last name"
                    invalid={"lastName" in errors}
                    ref={register({ required: true })} />

                <FieldLabel htmlFor="lastName">
                </FieldLabel>

                <TextInput
                    name="handle"
                    placeholder="Username / Handle"
                    invalid={"handle" in errors}
                    ref={register({ required: true })} />

                <FieldLabel htmlFor="handle">
                  This is your unique handle name. Your handle can represent your business or personal brand.
                </FieldLabel>
              </FormFields>

              <SectionTitle>Is Private
                <div style={{display: "inline", marginLeft: "1rem"}}>
                  <Controller
                    name="isPrivate"
                    control={control}
                    render={(props) => <Switch {...props} />} />
                </div>
              </SectionTitle>
            </Section>

            <Section>
              <LocationSearchSection
                  title="Hometown"
                  subtitle="Add your hometown"
                  invalid={"homeLocation" in errors}
                  value={searchTarget}
                  onChange={setSearchTarget} />
            </Section>

            <Section>
              <SectionTitle>Bio</SectionTitle>

              <FormFields column>
                <TextArea
                    name="bio"
                    placeholder="Tell us a little about yourself"
                    ref={register({ required: true })} />
              </FormFields>
            </Section>
          </Form>
        </Container>
      </Root>
    )
  }

export default ProfileEditPage