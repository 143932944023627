export enum UserStatus {
  Active = "ACTIVE"
}

export interface UserProfile {
  id: string
  firstName: string
  lastName: string
  handle: string
  email: string
  bio: string
  homeLocation: string
  homeLocationGeo: string
  imageUrl: string
  isPrivate: boolean
  isSystemAdministrator: boolean
  isTourGuide: boolean
  isVODEnabled: boolean
  status: UserStatus
}

export interface TourGuide {
  id: string
  firstName: string
  lastName: string
  handle: string
  email: string
  bio: string
  imageUrl: string
  status: UserStatus
}

export interface MediaData {
  contentType: string
}

export interface StreamVideoDetails {
  hlsPlaylist: string
  hlsUrl: string
}

export interface MediaUploadResponse {
  mediaId: string
  s3PutObjectUrl: string
}

export enum TourType {
  Video = "VIDEO",
  Broadcast = "BROADCAST",
  LiveVideo = "LIVEVIDEO",
}

export enum TourStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Ended = "ENDED"
}

export interface TourStats {
  currentConnectionCount: number
}

export interface Tour {
  id: string

  userId: string

  name: string
  guideName: string
  tags: string[]

  type: TourType
  tourType: TourType

  tourGuideId: string
  tourGuideSummary: any

  tourAddress: string
  tourDescripition: string
  tourDuration: number
  tourLocation: string

  city: string
  district: string
  country: string
  region: string
  continent: string
  property: string
  point: string

  aspectRatioHeight: number
  aspectRatioWidth: number

  date: string
  createDate: string

  imageUrl: string
  imageMediaData: MediaData
  streamVideoStatus: string
  streamVideoDetails: StreamVideoDetails
  videoMediaData: MediaData
  videoUrl: string

  is360: boolean
  isFeatured: boolean

  stats: TourStats

  status: TourStatus
}