import React from 'react';

import { useTours } from "../../hooks/useTours"

import { MarkerProps, ZoneProps, mapOptions } from "./IMapProps"
import MapMarker from "./MapMarker"

import GoogleMapReact from "google-map-react"

import styled from "styled-components"
import "./map.scss"

import config from "../../config";
const API_KEY = config.mapsApi;

interface IMapComponent {
  center?: { lat: number, lng: number }
  zoom?: number
  zones?: ZoneProps[]
  markers?: MarkerProps[]
  credentialed?: firebase.User | null
  tours?: any
  fullHeight: boolean
  hideTours?: boolean
  target?: any
  point?: string
  onClick? (a: any, b: any): void
  onGoogleApiLoaded? (map: any): void
}

const Root = styled.div<{ fullHeight: boolean }>`
  position: relative;
  flex-grow: 1;

  ${ p => p.fullHeight && `
    height: 100%;
  `}

  padding-top: 56%;
`
const Main = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
`

const pointToLatLng = (point: string) => {
  const [ lat, lng ] = point.split(",").map(it => parseFloat(it))
  return { lat, lng }
}

const MapComponent: React.FC<IMapComponent> = ({ center, zoom, fullHeight, hideTours, target, onClick, onGoogleApiLoaded, point }) => {
  const { tours } = useTours();
 
  let defaultLatLng = { lat: 38.892060, lng: -77.019910 }
  if (point) {
    defaultLatLng = pointToLatLng(point)
  }
  return (
    <Root fullHeight={fullHeight}>
      <Main>
        <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY, libraries: ['places'] }}
            center={center || defaultLatLng}
            zoom={zoom || 1}
            options={{...mapOptions, mapTypeId: "terrain"}}
            yesIWantToUseGoogleMapApiInternals
            onChildClick={onClick}
            onGoogleApiLoaded={onGoogleApiLoaded}>

          { !hideTours && tours.map(
            it =>
              <MapMarker
                  key={it.id}
                  tour={it}
                  {...pointToLatLng(it.point)} />
          )}
    
            <MapMarker
              lat={target ? target.geometry.location.lat() : defaultLatLng.lat}
              lng={target ? target.geometry.location.lng() : defaultLatLng.lng} />

        </GoogleMapReact>
      </Main>
    </Root>
  )
}

export default MapComponent