import React from "react"

import { useUserProfile } from "../../hooks/useUserProfile"

import { GuideListItemPropsT } from "."

import Button from "../Button"

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
} from "../List"

import styled from "styled-components"

import AvatarPlaceholder from "../../images/icons/avatar-placeholder.png"

const Root = styled(ListItem)`
  margin: 0.5rem;
  padding: 1.3rem 1.2rem;
  min-width: 360px;
  border-radius: 1rem;
  background-color: var(--theme-dark-6);
`
const ItemIcon = styled(ListItemIcon)`
  width: 96px; height: 96px;
`

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

const PrimaryText = styled(ListItemPrimaryText)`
  margin-left: 1rem;
  font-size: 20px;
`
const SecondaryText = styled(ListItemSecondaryText)`
  margin-left: 1rem;
  font-size: 16px;
`

const GuideListItemExpanded: React.FC<GuideListItemPropsT> =
  ({ item, onClick }) => {
    const profile = useUserProfile()

    const onFollow = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      profile.follow(item.id)
      ev.stopPropagation()
    }

    return (
      <Root onClick={onClick}>
        <ItemIcon>
          <ProfileImage src={item.imageUrl || AvatarPlaceholder} />
        </ItemIcon>

        <ListItemText>
          <PrimaryText>{item.firstName} {item.lastName}</PrimaryText>
          <SecondaryText>{item.handle}</SecondaryText>
          <Button compact onClick={onFollow}>Unfollow</Button>
        </ListItemText>
      </Root>
    )
  }

export default GuideListItemExpanded