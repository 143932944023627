import React from "react"
import { TourListItemPropsT } from "./TourListItem"
import styled from "styled-components"
import { isLive } from "../../common/utils"

import AvatarPlaceholder from "../../images/icons/avatar-placeholder.png"

const Root = styled.li`
  margin: 1rem 0;
  max-width: 260px;
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ListItemIcon = styled.div`
  position: relative;
  margin-right: 0.5rem;
  min-width: 42px;
  min-height: 42px;
  max-width: 42px;
  max-height: 42px;
`
const ListItemText = styled.div`
  max-width: calc(100% - 42px - 1rem);

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > * {
    margin: 0.1rem;
  }
`
const ListItemPrimaryText = styled.span`
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`
const ListItemSecondaryText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: var(--theme-very-light-brown);
`
const ProfileImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
`
const LiveLabel = styled.span`
  position: absolute;
  bottom: -6px; left: 3px;
  width: 36px;
  font-size: 8px;
  font-weight: bolder;
  border-radius: 8px;
  background-color: var(--theme-robins-egg-blue);
  color: white;
  text-align: center;
`

const TourListItemCompact: React.FC<TourListItemPropsT> =
  ({ item, onClick }) => {
    return (
      <Root onClick={onClick}>
        <ListItemIcon>
          <ProfileImage src={item.tourGuideSummary.imageUrl || AvatarPlaceholder} />
          { isLive(item) && <LiveLabel>LIVE</LiveLabel> }
        </ListItemIcon>

        <ListItemText>
          <ListItemPrimaryText>{ item.name }</ListItemPrimaryText>
          <ListItemSecondaryText>With {!!item.guideName ? " " + item.guideName : " " + item.tourGuideSummary.firstName + " " + item.tourGuideSummary.lastName }</ListItemSecondaryText>
        </ListItemText>
      </Root>
    )
  }

export default TourListItemCompact
