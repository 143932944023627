import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"

import { Tour, TourStatus, TourType } from "./types"

dayjs.extend(duration)
dayjs.extend(relativeTime)

export const sortByProperty = (prop: string) => (a: any, b: any) => {
  if (a[prop] < b[prop]) return -1;
  if (a[prop] > b[prop]) return 1;
  return 0;
};

export const fmtDuration = (d: number) => {
  return dayjs.duration({ seconds: d }).humanize()
    .replace("minutes", "mins")
    .replace("minute", "min")
}

export const fmtTimeFromNow = (s: string) => {
  return dayjs(s).from(dayjs())
}

export const isBroadcast = (it: Tour) => it.tourType === TourType.Broadcast
export const isLive = (it: Tour) => isBroadcast(it) && it.status === TourStatus.Active
export const isUpcoming = (it: Tour) => isBroadcast(it) && it.status === TourStatus.Pending
export const isNew = (it: Tour) => dayjs().diff(dayjs(it.createDate), "day") < 7