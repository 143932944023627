import React from "react"
import { Link, useHistory } from "react-router-dom"

import { useFirebase } from "../../hooks/useFirebase"
import { useUserProfile } from "../../hooks/useUserProfile"
import { useTours } from "../../hooks/useTours"

import Tabs from "../../components/Tabs"

import TourList from "../../components/TourList"
import GuideList from "../../components/GuideList"

import {
  Root,
  Container,
  Header,
  ProfileImage,
  ProfileInfo,
  ProfileName,
  ProfileHandle,
  ProfileFollowers,
  Main,
  ProfileBio,
  ProfileHometown
} from "../../components/UserProfile"
import Button from "../../components/Button"
import { sortByProperty } from "../../common/utils"

const ProfileViewPage: React.FC =
  () => {
    const history = useHistory()

    const { user } = useFirebase()
    const profile = useUserProfile()

    const { tours, isLoading } = useTours()
    const myTours = tours.filter(it => it.tourGuideId === profile.id).sort(sortByProperty("date")).reverse()

    return (
      !isLoading ? (
        <Root>
        <Container>
          <Header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProfileImage src={ profile.imageUrl || user?.photoURL || "" }/>

              <ProfileInfo>
                <ProfileName>{ profile.firstName } { profile.lastName }</ProfileName>
                <ProfileHandle>{ /*profile.handle*/ "" }</ProfileHandle>
                <div style={{display: "flex"}}>
                  {profile.homeLocation && <ProfileHometown>{ profile.homeLocation }</ProfileHometown>}
                  <ProfileFollowers>{ profile.following.length } following</ProfileFollowers>
                </div>
              </ProfileInfo>

              { profile.isTourGuide && profile.isVODEnabled &&
                <Link to="/tours/upload">
                  <Button>Upload Video</Button>
                </Link>
              }

              <Link to="/profile/edit">
                <Button secondary>Edit Profile</Button>
              </Link>
            </div>

            <ProfileBio>{ profile.bio }</ProfileBio>
          </Header>

          <Main>
            <Tabs>
              { profile.isTourGuide &&
                <Tabs.TabPane tab="My Tours" key={1}>
                  <TourList owned={true} expanded noscroll items={myTours} onItemClick={(it) => history.push(`/tours/${it.id}`)} />
                </Tabs.TabPane>
              }
              <Tabs.TabPane tab="Recently Watched" key={2}>
                <TourList expanded noscroll items={tours} onItemClick={(it) => history.push(`/tours/${it.id}`)} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Following" key={3}>
                <GuideList expanded noscroll items={profile.following} onItemClick={(it) => history.push(`/guides/${it.id}`)} />
              </Tabs.TabPane>
            </Tabs>
          </Main>
        </Container>
      </Root>
      ) : null
    )
  }

export default ProfileViewPage