import React, { createContext, useContext, useEffect, useState } from "react"

//import Axios from "axios"

import { TourGuide, UserProfile } from "../common/types"
import { useFirebase } from "./useFirebase"

import {
  createMe,
  getMe,
  getFollowers,
  getFollowing,
  followGuide,
  updateMe,
  updateProfileImage,
} from "../common/api-client"
import Axios from "axios"

export interface IUserProfileProviderT extends UserProfile {
  isLoading: boolean

  followers: UserProfile[]
  following: TourGuide[]

  follow (guideId: string): Promise<void>
  profile: any

  update (profile: UserProfile): Promise<void>
  setProfileImage (image: Blob): Promise<void>
}

export const Context = createContext<IUserProfileProviderT>({
  followers: [] as UserProfile[],
  following: [] as TourGuide[],
  isLoading: true
} as IUserProfileProviderT)

export const useUserProfile = () => useContext(Context)

export const UserProfileProvider: React.FC =
  ({ children }) => {
    const { user } = useFirebase()

    const [ isLoading, setLoading ] = useState<boolean>(true)
    const [ profile, setProfile ] = useState<UserProfile>({} as UserProfile)

    const [ followers, setFollowers ] = useState<UserProfile[]>([])
    const [ following, setFollowing ] = useState<TourGuide[]>([])

    const refresh = () => {
      if (!user || user.isAnonymous) return;

      return getMe()
        .then(async (profile) => {
          // If not found, then lets create one.
          if (profile.error && profile.error === "User profile not found") {
            const { email, displayName, photoURL } = user
            const [ firstName, lastName ] = (displayName || "").split(" ")

            profile = await createMe({
              firstName, lastName, handle: email,
              email: email,
              primaryLanguage: navigator.language,
              imageUrl: photoURL,
            })
          }
  /* 
          const { photoURL } = user
          if (photoURL) {
            const response = await Axios.get(photoURL)
            const { s3PutObjectUrl } = (await updateProfileImage(response.headers["content-type"]))

            console.info("***", Buffer.from(response.data).toString("hex"))

            console.info("Updating profile image");
            await Axios.put(
              s3PutObjectUrl,
              response.data,
              {
                headers: { "content-type": response.headers["content-type"] }
              }
            );
            profile = await getMe();
          }
  */
          const followers = await getFollowers()
          const following = await getFollowing()

          console.info(profile)
          setProfile(profile)

          setFollowers(followers)
          setFollowing(following)

          setLoading(false)
        })
        .catch(e => {
          console.error("Failed to get user profile:", e)
          setLoading(false)
        })
    }

    useEffect(() => {
      if (!user || user.isAnonymous) {
        setProfile({} as UserProfile)
        setLoading(false)
        return
      }

      refresh();
    }, [user])

    const follow = async (guideId: string) => {
      await followGuide(guideId)
        .then(() => getFollowing())
        .then(r => {
          setFollowing(r)
        })
        .catch(e => {
          console.error("Failed to follow guide:", e)
        })

      return
    }

    const update = async (profile: UserProfile) => {
      setLoading(true);

      await updateMe(profile);

      setLoading(false);
    }

    const setProfileImage = async (image: Blob) => {
      setLoading(true);

      await updateProfileImage("image/jpeg")
        .then(r => {
          return Axios.put(r.s3PutObjectUrl, image, {
            headers: { "Content-Type": "image/jpeg" },
          });
        })
        .then(r => {
          console.info("Done", r);
          return refresh();
        })
        .catch(e => {
          console.error("Failed to upload profile image:", e);
        });

      setLoading(false);
    }

    return (
      <Context.Provider value={{
            isLoading,
            followers,
            following,
            follow,
            update,
            setProfileImage,
            profile,
            ...profile
          }}>
        { children }
      </Context.Provider>
    )
  }
