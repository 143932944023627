import styled, { css } from "styled-components"

import Switch from "./Switch"

export const Section = styled.div`
  margin: 0 0 30px 0;
  padding: 40px;
  background-color: var(--theme-dark-1);
  border-radius: 20px;
`
export const SectionTitle = styled.h4`
  margin: 8px 0;
  font-size: 26px;
  font-weight: 500;
`
export const SectionSubtitle = styled.h5`
  margin: 8px 0;
  font-size: 14px;
  color: white;
`
export const SectionDescription = styled.p`
  margin: 8px 0;
  font-size: 14px;
  color: var(--theme-blue-grey-2);
`
export const FormFields = styled.div<{column?: boolean}>`
  margin: 18px 0 32px;
  display: flex;

  * {
    margin: 8px;

    :first-child {
      margin-left: 0;
    }
  }

  ${Switch} {
    * { margin: 0; }
  }

  ${ p => p.column && css`
    flex-direction: column;
    * { margin: 8px 0; }
  `}

  ${ p => !p.column && css`
    align-items: center;
  `}
`
export const SectionList = styled.ul`
  list-style: none;
`
export const SectionListItem = styled.li<{isActive?:boolean}>`
  position: relative;
  padding: 28px 38px;
  font-size: 15px;
  color: white;

  display: flex;
  align-items: center;

  border-top: 1px solid var(--theme-border-color);

  cursor: pointer;

  :last-of-type {
    border-bottom: 1px solid var(--theme-border-color);
  }

  > :first-child {
    flex-grow: 1;
  }

  > span[role=img] {
    width: 22px;
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: var(--theme-very-light-brown);

    font-size: 10px;
  }

  ${p => p.isActive && css`
    background-color: var(--theme-dusk);
  `}
`