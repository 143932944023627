import styled from "styled-components"
import { DatePicker as AntDatePicker } from "antd"

export const DatePicker = styled(AntDatePicker)`
  padding: 8px 28px;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  background-color: var(--theme-dark-3);
  outline: none;

  .ant-picker-input {
    margin: 0;
    padding: 0;

    > input {
      margin: 0;
      padding: 0;
      color: white;

      ::placeholder {
        color: var(--theme-blue-grey-2);
      }
    }
  }

  .ant-picker-suffix {
    margin: 0;
    padding: 0;

    > span[role=img] {
      margin: 0;
      padding: 0;
      color: white;
    }
  }

  .ant-picker-clear {
    display: none;
  }
`