import { useEffect, useState } from "react"

const useScaleImage =
  (image: any, size: number, type: string = "image/jpeg", quality?: any):
    [ string | null, Blob | null ] => {
      const [ preview, setPreview ] = useState<string | null>(null)
      const [ result, setResult ] = useState<Blob | null>(null)

      useEffect(() => {
        if (!image) return

        const el = document.createElement("img")
        el.onload = () => {
          const canvas = document.createElement("canvas")
          canvas.width = size
          canvas.height = size

          const imageSz = Math.min(el.width, el.height)
          const xOffset = (el.width - imageSz) / 2
          const yOffset = (el.height - imageSz) / 2

          const ctx = canvas.getContext("2d")
          if (!ctx) {
            console.error("Failed to get 2d canvas drawing context.");
            return;
          }

          ctx.drawImage(
            el,
            xOffset, yOffset,
            imageSz, imageSz,
            0, 0,
            size, size
          )

          setPreview(canvas.toDataURL(type))
          canvas.toBlob(setResult, type, quality)
        }

        el.src = image
      }, [image])

      return [ preview, result ]
    }

export default useScaleImage
