import React, { KeyboardEvent, useEffect, useState } from "react"
import styled from "styled-components"

import SearchIcon from "../../images/icons/search.png"

const Root = styled.div`
  padding: 0.5rem 1rem 0.5rem 3rem;

  border: 0;
  border-radius: 1rem;

  background-color: var(--theme-dark-6);
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 1rem 50%;
`
const Input = styled.input`
  font-size: 14px;

  border: none;
  color: white;
  background-color: var(--theme-dark-6);
  outline: none;

  ::placeholder {
    color: white;
    opacity: 0.65;
  }
`
Input.defaultProps = { placeholder: "Search..." }

interface SearchInputPropsT {
  onChange? (q: string): void
  onSearch? (q: string): void
}
const SearchInput: React.FC<SearchInputPropsT> =
  ({ onChange, onSearch }) => {
    const [ value, setValue ] = useState<string>("")

    useEffect(() => {
      if (!onChange) return
      onChange(value)
    }, [value])

    const keyUp = (ev: KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Enter" && onSearch) onSearch(value)
    }

    return (
      <Root>
        <Input value={value} onChange={(ev) => setValue(ev.target.value)} onKeyUp={keyUp} />
      </Root>
    )
  }

export default SearchInput