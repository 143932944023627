import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { Tour, TourGuide, UserProfile } from "../../common/types"

import { useFirebase } from "../../hooks/useFirebase"
import { useUserProfile } from "../../hooks/useUserProfile"

import { useTourGuides } from "../../hooks/useTourGuides"
import { useTours } from "../../hooks/useTours"

import Button from "../../components/Button"
import TourList from "../../components/TourList"

import {
  Root,
  Container,
  Header,
  ProfileImage,
  ProfileInfo,
  ProfileName,
  ProfileHandle,
  ProfileFollowers,
  ProfileBio,
  Main
} from "../../components/UserProfile"

interface ParamsT {
  guideId: string
}
const GuideViewPage: React.FC =
  () => {
    const history = useHistory()

    const { user } = useFirebase()
    const profile = useUserProfile()

    const { guideId } = useParams<ParamsT>()
    const { guides, getFollowers } = useTourGuides()

    const { tours } = useTours()
    const [ filteredTours, setFilteredTours ] = useState<Tour[]>([])

    const [ guide, setGuide ] = useState<TourGuide | null>(null)
    const [ followers, setFollowers ] = useState<UserProfile[]>([])

    const isFollowing = !!profile.following.find(it => it.id === guideId)

    useEffect(() => {
      setGuide(guides.find(it => it.id === guideId) || null)
      setFilteredTours(tours.filter(it => it.userId === guideId))
      getFollowers(guideId).then(r => setFollowers(r))
    }, [ guideId, guides, tours, getFollowers ])

    if (!guide) return <></>

    return (
      <Root>
        <Container>
          <Header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ProfileImage src={ guide.imageUrl || "" }/>

              <ProfileInfo>
                <ProfileName>{ guide.firstName } { guide.lastName }</ProfileName>
                <ProfileHandle>{ guide.handle }</ProfileHandle>
                <ProfileFollowers>{ followers.length } followers</ProfileFollowers>
              </ProfileInfo>

              { profile.isLoading &&
                <Button textOnly>Loading...</Button>
              }
              { !profile.isLoading && !user?.isAnonymous &&
                <Button secondary={!isFollowing} onClick={() => profile.follow(guideId)}>
                  { isFollowing ? "Following" : "Follow" }
                </Button>
              }
            </div>

          <ProfileBio>{ guide.bio }</ProfileBio>
        </Header>

          <Main>
            <TourList expanded noscroll items={filteredTours} onItemClick={(it) => history.push(`/tours/${it.id}`)} />
          </Main>
        </Container>
      </Root>
    )
  }

export default GuideViewPage