import React, { useEffect, useRef, useState } from "react"

import { TextInput } from "../TextInput"

import {
  Section,
  SectionTitle,
  SectionDescription,
  FormFields,
} from "../../components/FormCommon"

import MapComponent from "../Map/MapComponent"

interface LocationSearchPropsT {
  title?: string;
  subtitle?: string;
  value: any;
  invalid?: boolean;
  point?: string;
  defaultText?: string;
  onChange (place: any): void;
}
export const LocationSearchSection = React.forwardRef<HTMLDivElement, LocationSearchPropsT>(
  ({ title, subtitle, value, invalid, onChange, point, defaultText }, ref) => {
    const [ map, setMap ] = useState<any | null>(null)
    const [ mapsApi, setMapsApi ] = useState<any | null>(null)

    const [ searchBox, setSearchBox ] = useState<any | null>(null)
    const searchRef =  useRef<HTMLInputElement>(null)
  
    useEffect(() => {
      if (map === null || mapsApi === null) return

      if (searchBox === null) {
        setSearchBox(new mapsApi.places.SearchBox(searchRef.current))
        return
      }

      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces()
        console.info(places[0])
        onChange(places[0] || null)
      })

      //return () => searchBox.removeListener("places_changed")
    }, [ onChange, map, mapsApi, searchBox ])

    const onGoogleApiLoaded = (props: any) => {
      setMap(props.map)
      setMapsApi(props.maps)
    }

    const onMapClick = (a: any, b: any) => {
      console.info(a, b)
    }

    return (
      <Section ref={ref}>
        <SectionTitle>{title || "Location"}</SectionTitle>
        <SectionDescription>{subtitle || "Tell us where your tour began."}</SectionDescription>
        <FormFields column>
          <TextInput ref={searchRef}
              invalid={invalid}
              name="tourAddress"
              onKeyPress={(e) => { if (e.key === 'Enter') return; }}
              placeholder={defaultText} />

          <MapComponent hideTours fullHeight={false}
              center={value ? {
                lat: value.geometry.location.lat(),
                lng: value.geometry.location.lng(),
              } : undefined}
              point={point}
              zoom={(value || point) && 12}
              target={value}
              onGoogleApiLoaded={onGoogleApiLoaded}
              onClick={onMapClick} />
        </FormFields>
      </Section>
    )
  }
)
