import styled from "styled-components"

import { Tabs as AntTabs } from "antd"

const Tabs = styled(AntTabs)`
  .ant-tabs-nav::before {
    border-color: var(--theme-border-color);
  }

  .ant-tabs-nav-wrap {
    margin: 0 2rem;
  }

  .ant-tabs-tab {
    margin: 0.5rem 1rem;

    .ant-tabs-tab-btn {
      margin: 0;
      font-size: 18px;
      font-weight: bold;

      color: white;
      opacity: 0.50;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      opacity: 1;
    }
  }

  .ant-tabs-ink-bar {
    background-color: var(--theme-light-aquamarine);
  }

  .ant-tabs-content-holder {
    padding: 0 2rem 0 2rem;
  }
`

Tabs.TabPane = styled(AntTabs.TabPane)`
`

export default Tabs